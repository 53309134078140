import Checkbox from 'expo-checkbox'
import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { colors } from '../../Helpers/variables'

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: '100%'
		// marginHorizontal: 16,
		// marginVertical: 32
	},
	section: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%'
	},
	paragraph: {
		color: 'white',
		fontSize: 16,
		height: 30,
		marginTop: 10
	},
	checkbox: {
		margin: 8
	}
})

export const CheckboxTicker = ({ isChecked, label, handleCheckboxClick, item = undefined, style }) => {
	const handleCheckbox = value => {
		if (!item) handleCheckboxClick(!value) // value is boolean
		else handleCheckboxClick(item, !value)
	}

	return (
		<View style={styles.container}>
			<View style={styles.section}>
				<Checkbox
					style={styles.checkbox}
					value={isChecked}
					color={isChecked ? colors.accent : undefined}
					onValueChange={handleCheckbox}
				/>
				<Pressable onPress={() => handleCheckbox(!isChecked)}>
					<Text style={[styles.paragraph, style]}>{label}</Text>
				</Pressable>
			</View>
		</View>
	)
}
