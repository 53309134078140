import axios from 'axios'
import * as SecureStore from 'expo-secure-store'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { PanResponder, Platform, View } from 'react-native'
import { CopilotProvider } from 'react-native-copilot'
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import { USERTYPE_URL, USER_DEVICE_URL, colors } from '../../Helpers/variables'
import useAudio from '../../Hooks/useAudio'
import useAuth from '../../Hooks/useAuth'
import useLayout from '../../Hooks/useLayout'
import useTimer from '../../Hooks/useTimer'
import useTokens from '../../Hooks/useTokens'
import AuthService from '../../Services/AuthService'
import { NotificationModal } from '../Modals/NotificationModal'
import Sidebar from './Sidebar/Sidebar'

export default function MainLayout({ children }) {
	const [padding, setPadding] = useState(0)
	const { isSidebarOpen } = useLayout()
	const { getTokens } = useTokens()
	const timerId = useRef(false)
	const { sound } = useAudio()
	const { clearTimeoutId } = useTimer()
	const { setIsLoggedIn, setIsAdminViewOpen, setIsAdmin, isTourModalVisible, setIsTourModalVisible } = useAuth()
	const [isAnnouncementsModalVisible, setIsAnnouncementsModalVisible] = useState(false)
	const [isTermsConditionsModalVisible, setIsTermsConditionsModalVisible] = useState(false)
	const [currentTags, setCurrentTags] = useState(undefined)

	useLayoutEffect(() => {
		if (Platform.OS === 'android') setPadding(15)
		if (Platform.OS === 'ios') setPadding(30)
		if (Platform.OS === 'web') setPadding(0)
	}, [])

	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: ''
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: ''
		})
		handleLogout()
	}

	const panResponder = useRef(
		PanResponder.create({
			onStartShouldSetPanResponderCapture: () => {
				resetInactivityTimeout()
			}
		})
	).current

	const resetInactivityTimeout = () => {
		clearTimeout(timerId.current)
		timerId.current = setTimeout(() => {
			setNotifModal({
				visible: true,
				title: 'Notifikation',
				description: 'Du er blevet logget ud på grund af inaktivitet'
			})
		}, 36000000)
	}

	const handleLogout = async () => {
		AuthService.logout()

		try {
			await sound.current.unloadAsync()
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
		setIsLoggedIn(false)
		setIsAdminViewOpen(false)
		clearTimeoutId()
	}

	const getUsertype = async () => {
		const { access } = await getTokens()
		try {
			const { data } = await axios.get(USERTYPE_URL, {
				headers: { Authorization: `JWT ${access}` }
			})
			if (data.usertype === 4 || data.usertype === 5) setIsAdmin(true)
			else setIsAdmin(false)
		} catch (ex) {
			setIsAdmin(false)
		}
	}

	const setUniqueDeviceId = async () => {
		// console.log('setUniqueDeviceId')
		let uuid = uuidv4()
		let labelDeviceId = 'musicmind_secure_deviceid'
		let fetchuuid = (Platform.OS !== 'web') ? await SecureStore.getItemAsync(labelDeviceId) : localStorage.getItem(labelDeviceId)
		if (fetchuuid) {
			uuid = fetchuuid
		}
		(Platform.OS !== 'web') ? await SecureStore.setItemAsync(labelDeviceId, uuid) : localStorage.setItem(labelDeviceId, uuid)
		return uuid
	}

	const addUserDevice = async (device_id, access) => {
		console.log('addUserDevice')
		let guided_tour_tag = false
		let terms_conditions_tag = false
		let announcements_tag = false
		try {
			axios
				.post(
					USER_DEVICE_URL,
					{
						device_id,
						guided_tour_tag,
						terms_conditions_tag,
						announcements_tag
					},
					{
						headers: { Authorization: `JWT ${access}`, 'Content-Type': 'application/json' }
					}
				)
				.then(response => {
					if (response.status === 201) {
						// if (!data.terms_conditions_tag) {
						// 	setIsTermsConditionsModalVisible(true)
						// } else {
						// 	if (!data.guided_tour_tag) {
						// 		setIsTourModalVisible(true)
						// 	} else if (!data.announcements_tag) {
						// 		setIsAnnouncementsModalVisible(true)
						// 	}
						// }
						if (!response.data.announcements_tag) {
							setIsAnnouncementsModalVisible(true)
						}

						setCurrentTags({
							device_id,
							guided_tour_tag,
							terms_conditions_tag,
							announcements_tag
						})
					} else {
						console.log('addUserDevice: ' + response.status)
					}
				})
				.catch(err => {
					console.log(err)
				})
		} catch (ex) {
			console.log(ex)
		}
	}

	const updateUserDevice = async (deviceId, access, guided_tour_tag, terms_conditions_tag, announcements_tag) => {
		console.log('updateUserDevice')
		let item = currentTags
		let newItem = {
			device_id: item.device_id,
			guided_tour_tag: item.guided_tour_tag,
			terms_conditions_tag: item.terms_conditions_tag,
			announcements_tag: item.announcements_tag
		}
		if (guided_tour_tag && terms_conditions_tag && announcements_tag) {
			newItem.guided_tour_tag = guided_tour_tag
			newItem.terms_conditions_tag = terms_conditions_tag
			newItem.announcements_tag = announcements_tag
		} else if (guided_tour_tag) {
			newItem.guided_tour_tag = guided_tour_tag
		} else if (terms_conditions_tag) {
			newItem.terms_conditions_tag = terms_conditions_tag
		} else if (announcements_tag) {
			newItem.announcements_tag = announcements_tag
		}
		const { status } = await axios.put(USER_DEVICE_URL + deviceId, newItem, {
			headers: { Authorization: `JWT ${access}` }
		})

		if (status === 200) {
			console.log('SUCCESSFULLY UPDATED')
			setCurrentTags(newItem)
		} else {
			console.log('FAILED TO UPDATE')
		}
	}

	const getUserDevice = async () => {
		console.log('getUserDevice')
		try {
			const deviceId = await setUniqueDeviceId()
			//call endpoint to get terms and condition and guided tour tag
			const { access } = await getTokens()
			const { data } = await axios.get(USER_DEVICE_URL, {
				headers: { Authorization: `JWT ${access}` }
			})
			let isExist = data.filter(x => x.device_id === deviceId).length > 0
			if (isExist) {
				let item = data.filter(x => x.device_id === deviceId)[0]
				// if (!item.terms_conditions_tag) {
				// 	setIsTermsConditionsModalVisible(true)
				// } else {
				// 	if (!item.guided_tour_tag) {
				// 		setIsTourModalVisible(true)
				// 	} else if (!item.announcements_tag) {
				// 		setIsAnnouncementsModalVisible(true)
				// 	}
				// }
				if (!item.announcements_tag) {
					setIsAnnouncementsModalVisible(true)
				}
				setCurrentTags(item)
			} else {
				//add device
				await addUserDevice(deviceId, access)
			}
		} catch (ex) {
			console.log(ex)
		}
	}

	const handleCancelTermsConditions = async () => {
		AuthService.logout()
		setIsLoggedIn(false)
	}

	const handleAgreeTermsConditions = async () => {
		const deviceId = await setUniqueDeviceId()
		const { access } = await getTokens()
		await updateUserDevice(deviceId, access, undefined, true, undefined)
		setIsTermsConditionsModalVisible(false)
		setIsTourModalVisible(true)
	}

	const handleCancelGuidedTour = async () => {
		const deviceId = await setUniqueDeviceId()
		const { access } = await getTokens()
		await updateUserDevice(deviceId, access, true, undefined, undefined)
		setIsTourModalVisible(false)
		if (!currentTags.announcements_tag) {
			setIsAnnouncementsModalVisible(true)
		}
	}

	const handleAgreeAnnouncements = async () => {
		const deviceId = await setUniqueDeviceId()
		const { access } = await getTokens()
		await updateUserDevice(deviceId, access, undefined, undefined, true)
		setIsAnnouncementsModalVisible(false)
	}

	useEffect(() => {
		const boot = async () => {
			/* ------------------ check for user login and permissions ------------------ */
			const response = await AuthService.isLoggedIn()
			if (response) {
				setIsLoggedIn(response)
				getUsertype()
				getUserDevice()
			}
			resetInactivityTimeout()
		}
		boot()
	}, [])

	return (
		<View
			style={{
				backgroundColor: colors.primary,
				paddingTop: padding + 10,
				flex: 1
			}}
			{...panResponder.panHandlers}
		>
			<View style={{ flexDirection: 'row', flex: 1 }}>
				<View style={{ width: isSidebarOpen ? 275 : 100 }}>
					<CopilotProvider
						animated={true} // Can be true or false
						overlay={'view'} // Can be either view or svg
						labels={{ previous: 'Tidligere', next: 'Næste', skip: 'Springe', finish: 'Afslut' }}
						backdropColor={'rgba(82, 82, 82, 0.9)'}
						androidStatusBarVisible={true}
					>
						<Sidebar
							isTourModalVisible={isTourModalVisible}
							handleCancelGuidedTour={handleCancelGuidedTour}
							handleAgreeTermsConditions={handleAgreeTermsConditions}
							handleCancelTermsConditions={handleCancelTermsConditions}
							isTermsConditionsModalVisible={isTermsConditionsModalVisible}
							handleAgreeAnnouncements={handleAgreeAnnouncements}
							isAnnouncementsModalVisible={isAnnouncementsModalVisible}
							setIsAnnouncementsModalVisible={setIsAnnouncementsModalVisible}
						/>
					</CopilotProvider>
				</View>
				<View style={{ flex: 1, paddingRight: 10 }}>{children}</View>
			</View>
			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
				/>
			)}
		</View>
	)
}
