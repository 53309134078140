import React, { useRef } from 'react'
import { Audio } from 'expo-av'

export const AudioContext = React.createContext()

export const AudioProvider = ({ children }) => {
	const sound = useRef(new Audio.Sound())
	return (
		<AudioContext.Provider
			value={{
				sound,
                Audio
			}}
		>
			{children}
		</AudioContext.Provider>
	)
}
