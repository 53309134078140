import { useEffect, useState } from 'react'
import { Text, View, useWindowDimensions } from 'react-native'
import truncateStr from '../../Helpers/truncateStr'
import { PrimaryButton } from '../Buttons/Button'
import { userStyles } from './UserStyles'

const User = ({ user, handleEditUser, buttonTitle = 'Rediger', ...props }) => {

	const { width, height } = useWindowDimensions();
	const checkLargeScreen = () => {
		return width > 700
	}

	const checkPortraitScreen = () => {
		return (height > 500 && height <= 700 && width <= 400)
	}

	const [isWideScreen, setIsWideScreen] = useState(checkLargeScreen())
	const [isPortrait, setIsPortrait] = useState(checkPortraitScreen())

	const handleResize = () => {
		setIsWideScreen(checkLargeScreen())
		setIsPortrait(checkPortraitScreen())
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const userTypes = [
		{
			usertype: 1,
			usertypedesc: 'Tidsbegrænset Login'
		},
		{
			usertype: 3,
			usertypedesc: 'Almindelig Bruger'
		},
		{
			usertype: 4,
			usertypedesc: 'Super Bruger'
		}
	]
	return (
		<View style={userStyles.userRow} {...props}>
			<View style={userStyles.rowContainer}>
				<Text style={userStyles.albumTitle}>{truncateStr(user.username, 45)}</Text>
			</View>
			<View style={userStyles.flex1Container}>
				<Text style={userStyles.albumTitle}>{truncateStr(user.expiration_date, 45)}</Text>
			</View>
			<View style={userStyles.rowContainer}>
				<Text style={userStyles.albumTitle}>
					{truncateStr(userTypes.filter(x => x.usertype === user.user_type)[0].usertypedesc, 45)}
				</Text>
			</View>
			<View style={userStyles.buttonContainer}>
				<PrimaryButton title={buttonTitle} onPress={() => handleEditUser(user)}
					style={{ width: 'auto' }} />
			</View>
		</View>
	)
}

export default User
