import { Ionicons } from '@expo/vector-icons'
import { useState } from 'react'

import { Pressable, StyleSheet, View } from 'react-native'
import { colors } from '../../Helpers/variables'

export const SearchMenu = ({ handleSearch }) => {
	return (
		<View>
			<Pressable
				onPress={() => {
					handleSearch()
				}}
			>
				<Ionicons name="search" style={{ marginLeft: 10 }} size={30} color="white" />
			</Pressable>
		</View>
	)
}

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		// justifyContent: 'center',
		// alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	}
})
