import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

import axios from 'axios'
import { LOGIN_URL, USERNAME_URL } from '../Helpers/variables'
import { captureException } from '../Helpers/sentryLog'

const setTokens = async token => {
	if (Platform.OS === 'web') {
		localStorage.setItem('token', JSON.stringify(token))
	} else {
		try {
			await AsyncStorage.setItem('token', JSON.stringify(token))
		} catch (error) {
			captureException(error)
		}
	}
}

const setUser = async user => {
	if (Platform.OS === 'web') {
		localStorage.setItem('user', JSON.stringify(user))
	} else {
		try {
			await AsyncStorage.setItem('user', JSON.stringify(user))
		} catch (error) {
			captureException(error)
		}
	}
}

const getUser = async () => {
	let user = {}
	if (Platform.OS === 'web') {
		user = localStorage.getItem('user')
		return user
	} else {
		try {
			user = await AsyncStorage.getItem('user')
			return user
		} catch (error) {
			captureException(error)
		}
	}
}

const login = async (username, password) => {
	const { data } = await axios.post(LOGIN_URL, { username, password })
	if (data.refresh && data.access) {
		const user = await axios.get(USERNAME_URL, {
			headers: { Authorization: `JWT ${data.access}` }
		})
		
		setUser(user.data)
		setTokens(data)
	}

	return data
}

const logout = async () => {
	if (Platform.OS === 'web') {
		localStorage.removeItem('token')
	} else {
		try {
			await AsyncStorage.removeItem('token')
		} catch (error) {
			captureException(error)
		}
	}
}

const isLoggedIn = async () => {
	let token
	if (Platform.OS === 'web') {
		token = JSON.parse(localStorage.getItem('token'))
	} else {
		try {
			token = JSON.parse(await AsyncStorage.getItem('token'))
		} catch (error) {
			captureException(error)
		}
	}

	return token && token.access
}

const AuthService = {
	login,
	logout,
	isLoggedIn,
	getUser
}

export default AuthService
