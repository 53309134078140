import { Pressable, Text, View } from 'react-native'
import { v4 as uuidv4 } from 'uuid'
import Card from './components/Card'
import { colors } from '../../Helpers/variables'

export default function SearchResultPlaylist({ playlist, navigation, title, isShowNoDataLabel = true }) {
	return (
		<View>
			{playlist && (
				<View>
					<Text style={{ marginLeft: 20, fontSize: 20, color: colors.accent, fontWeight: 'bold', marginTop: 10 }}>
						{title}
					</Text>
					{playlist.length > 0 ? (
						<View>
							<View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								{playlist.map(item => (
									<Pressable
										onPress={() =>
											navigation.navigate('PlaylistTracks', {
												playlist: item.playlist ? item.playlist : item.id,
												playlistTitle: item.playlist_name ? item.playlist_name : item.name,
												playlistPhoto: item.Photo
											})
										}
										key={(item.playlist ? item.playlist : item.id) + uuidv4}
									>
										<Card image={item.Photo} title={item.playlist_name ? item.playlist_name : item.name} />
									</Pressable>
								))}
							</View>
						</View>
					) : (
						<Text style={{ color: 'white', marginLeft: 20, marginTop: 10 }}>{'Ingen data...'} </Text>
					)}
				</View>
			)}
		</View>
	)
}
