import React from 'react'
import { Alert, FlatList, Platform, Text, View } from 'react-native'
import { colors } from '../../Helpers/variables'
import { PrimaryButton } from '../Buttons/Button'
import { NotificationModal } from '../Modals/NotificationModal'
import { UserEditModal } from '../Modals/UserEditModal'
import User from './User'
import { userStyles } from './UserStyles'

export default function UserView({ userList, getUserList, hasAddUserBtn = false }) {
	const [isUserEditModalOpen, setUserEditModalOpen] = React.useState(false)
	const [editUserProps, setEditUserProps] = React.useState(undefined)
	const [notifModal, setNotifModal] = React.useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const handleEditUser = item => {
		setUserEditModalOpen(true)
		setEditUserProps(item)
	}

	const handleClose = () => {
		setUserEditModalOpen(false)
		setEditUserProps(undefined)
	}

	return (
		<View style={userStyles.userList}>
			{hasAddUserBtn && (
				<View>
					<PrimaryButton title={'Tilføj bruger'} onPress={() => setUserEditModalOpen(true)} style={{ width: 180 }} />
				</View>
			)}
			<FlatList
				data={userList}
				ListEmptyComponent={
					<View>
						<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>No users...</Text>
					</View>
				}
				renderItem={({ item }) => <User user={item} handleEditUser={handleEditUser} />}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
				keyExtractor={item => item.id}
				contentContainerStyle={{ paddingBottom: 80 }}
			/>
			{isUserEditModalOpen && (
				<UserEditModal
					modalVisible={isUserEditModalOpen}
					handleClose={handleClose}
					editUserProps={editUserProps}
					modalType={editUserProps === undefined ? 'add' : 'edit'}
					getUserList={getUserList}
					openNotifModal={openNotifModal}
					closeNotifModal={closeNotifModal}
				/>
			)}

			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
		</View>
	)
}
