import { Ionicons } from '@expo/vector-icons'
import React, { useEffect, useState } from 'react'
import { TouchableHighlight, View } from 'react-native'
import { Badge } from 'react-native-elements'
import { captureException } from '../../Helpers/sentryLog'
import { NEWS_ENGAGEMENT_URL, NEWS_FEED_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

import NewsFeedModal from '../Modals/NewsFeedModal'
import useLayout from '../../Hooks/useLayout'

const NewsFeedMenu = () => {
	const [modalVisible, setModalVisible] = useState(false)
	const [badgeCount, setBadgeCount] = useState(0)
	const [newsFeed, setNewsFeed] = useState([])
	const api = useAxios()
	const { isNewsFeedCountLoaded, setIsNewsFeedCountLoaded } = useLayout()

	const postNewsEngagement = async item => {
		const result = await api.post(NEWS_ENGAGEMENT_URL, {
			newsfeed: item.id,
			engagement: true
		})
		if (result.status !== 201) {
			console.log(result.status + ' - ' + result.statusText)
		}
	}

	const getNewsEngagement = async newsFeedTemp => {
		const result = await api.get(NEWS_ENGAGEMENT_URL)
		if (result.status === 200) {
			const newEngagement = result.data.filter(x => x.engagement === true)
			let newCount = 0
			newsFeedTemp.map(item => {
				let hasViewed = newEngagement.filter(x => x.newsfeed === item.id && x.engagement === true).length
				if (hasViewed === 0) newCount++
				else console.log('viewed')
			})
			setBadgeCount(newCount)
			return newEngagement
		} else {
			console.log(result.status + ' - ' + result.statusText)
			setBadgeCount(0)
			return []
		}
	}

	const getNewsFeed = async () => {
		await api
			.get(NEWS_FEED_URL)
			.then(async ({ data }) => {
				let newNewsFeed = []
				data.map((item, index) => {
					newNewsFeed.push({
						title: item.title,
						body: item.content,
						imgUrl: item.Photo,
						id: item.id
					})
				})
				await getNewsEngagement(newNewsFeed)
				setNewsFeed(newNewsFeed)
				setIsNewsFeedCountLoaded(true)
			})
			.catch(error => {
				captureException(error)
			})
	}

	useEffect(() => {
		if (!isNewsFeedCountLoaded) getNewsFeed()
	}, [])

	return (
		<View style={{ alignItems: 'center', justifyContent: 'center' }}>
			<TouchableHighlight
				onPress={e => {
					setModalVisible(true)
					setBadgeCount(0)
					newsFeed.map((item, index) => {
						postNewsEngagement(item)
					})
				}}
				// style={style}
			>
				<View style={{ flexDirection: 'row' }}>
					<Ionicons name="newspaper-outline" style={{ marginLeft: 15, fontSize: 30 }} color="white" />
					{badgeCount > 0 && (
						<Badge
							status="error"
							containerStyle={{
								position: 'absolute',
								marginLeft: 30
							}}
							value={badgeCount.toString()}
						/>
					)}
				</View>
			</TouchableHighlight>

			{modalVisible && (
				<NewsFeedModal modalVisible={modalVisible} setModalVisible={setModalVisible} newsFeed={newsFeed} />
			)}
		</View>
	)
}

export default React.memo(NewsFeedMenu)
