import { Ionicons } from '@expo/vector-icons'
import axios from 'axios'
import * as ImagePicker from 'expo-image-picker'
import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Alert, FlatList, Image, Modal, ScrollView, Text, View } from 'react-native'
import { PrimaryButton, SecondaryButton } from '../../Components/Buttons/Button'
import { Input, TextArea } from '../../Components/Forms/Input'
import { captureException } from '../../Helpers/sentryLog'
import { playlistSortByPosition } from '../../Helpers/sortArray'
import { colors, INTERNAL_CONTAINER_URL, INTERNAL_PLAYLIST_GROUP_URL, INTERNAL_URL } from '../../Helpers/variables'
import useTokens from '../../Hooks/useTokens'
import { trackStyles } from '../../Screens/TracksScreen/components/TrackStyles'
import { Select } from '../Forms/Select'
import useAxios from '../../Hooks/useAxios'
import { DataURIToBlob } from '../../Helpers/convertFile'
import uuid from 'react-native-uuid'

export default function InternalPlaylistContainerModal({
	setModalVisible,
	playlistInfo,
	modalVisible,
	getPlaylist,
	processType = 'update',
	setProcessType,
	playlist
}) {
	// const [statePlaylistInfo, setStatePlaylistInfo] = useState(playlistInfo)
	const [name, setName] = useState(playlistInfo ? playlistInfo.name : '')
	const [message, setMessage] = useState({ error: false, message: '' })
	const [description, setDescription] = useState(playlistInfo ? playlistInfo.description : '')
	const [positionState, setPosition] = useState(playlistInfo.position ? playlistInfo.position : 0)

	const [theme, setTheme] = useState(playlistInfo ? playlistInfo.theme : '')
	const [visibility, setVisibility] = useState(playlistInfo ? playlistInfo.visibility : '')
	const [loading, setLoading] = React.useState(false)
	const [Photo, setPhoto] = useState(null)
	const { getTokens } = useTokens()
	const [newPlaylist, setNewPlaylist] = useState('')
	const [internalPlaylist, setInternalPlaylist] = useState([])
	const [internalPlaylistGroupsOriginal, setInternalPlaylistGroupsOriginal] = useState([])
	const [internalPlaylistGroupsSelected, setInternalPlaylistGroupsSelected] = useState([])
	const [internalPlaylistGroupsRemoved, setInternalPlaylistGroupsRemoved] = useState([])

	const [positionNumbers, setPositionNumbers] = useState([])
	const [loadingPlaylistGroup, setLoadingPlaylistGroup] = useState(true)
	const api = useAxios()
	const [photoShow, setPhotoShow] = useState(null)

	const getInternalPlaylist = () => {
		let newItems = []
		api
			.get(INTERNAL_URL)
			.then(
				({ data }) => {
					// REVIEW: THIS IS A BAD IMPLEMENTATION AND WILL CAUSE PERFORMNCE ISSUES
					data.map((item, index) => {
						newItems.push({
							value: item.id,
							label: item.name
						})
						if (index === data.length - 1) {
							setInternalPlaylist(newItems)
							getInternalPlaylistGroups(newItems)
						}
					})
				},
				({ error }) => {
					console.log(error)
					captureException(error)
				}
			)
			.catch(error => {
				captureException(error)
			})
			.finally(() => setLoading(false))
	}

	const getInternalPlaylistGroups = internalPlaylistTemp => {
		if (playlistInfo.id) {
		api
			.get(INTERNAL_PLAYLIST_GROUP_URL + playlistInfo.id)
			.then(({ data }) => {
				data = playlistSortByPosition(data, false)
				setInternalPlaylistGroupsOriginal(data)
				setInternalPlaylistGroupsSelected(data)

				if (internalPlaylistTemp.length > 0) {
					data.map(item => {
						internalPlaylistTemp = internalPlaylistTemp.filter(x => x.value !== item.playlist)
					})
					setInternalPlaylist(internalPlaylistTemp)
				}
				let positionNumbersTemp = []
				for (let i = 0; i < data.length; i++) {
					positionNumbersTemp.push({
						value: String(i),
						label: String(i)
					})
				}
				setPositionNumbers(positionNumbersTemp)
			})
			.catch(error => {
				captureException(error)
			})
			.finally(() => {
				setLoading(false)
				setLoadingPlaylistGroup(false)
			})
		} else {
			setLoading(false)
			setLoadingPlaylistGroup(false)
		}
	}

	useEffect(() => {
		getInternalPlaylist()
	}, [playlistInfo])

	const onChangeSelectNewPlaylist = e => {
		if (e !== '') {
			let internalPlaylistGroupsSelectedTemp = internalPlaylistGroupsSelected
			let internalPlaylistTemp = internalPlaylistGroupsSelected.filter(x => x.playlist === e)[0]
			if (internalPlaylistTemp === undefined) {
				let checkItemExist = internalPlaylistGroupsOriginal.filter(x => x.playlist === e)[0]
				if (checkItemExist) {
					checkItemExist.processType = 'update'
					internalPlaylistGroupsSelectedTemp.push(checkItemExist)
				} else {
					internalPlaylistGroupsSelectedTemp.push({
						id: 'playlist' + e,
						playlist: e,
						playlist_name: internalPlaylist.filter(x => x.value === e)[0].label,
						position: internalPlaylistGroupsSelectedTemp.length,
						processType: 'add'
					})
				}

				internalPlaylistGroupsSelectedTemp = internalPlaylistGroupsSelectedTemp.sort(function (a, b) {
					return a.position - b.position
				})

				let positionNumbersTemp = []
				for (let i = 0; i < internalPlaylistGroupsSelectedTemp.length; i++) {
					positionNumbersTemp.push({
						value: String(i),
						label: String(i)
					})
				}
				setPositionNumbers(positionNumbersTemp)

				setInternalPlaylistGroupsSelected(internalPlaylistGroupsSelectedTemp)
			}
		}

		setNewPlaylist(e)
	}

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			allowsEditing: false,
			quality: 0,
			base64: false
		})

		console.log(result)

		if (result.cancelled) {
			return
	}

		let localUri = result.uri
		setPhotoShow(localUri)
		setPhoto(result)
	}

	const onChangePosition = (e, item) => {
		let selectedItems = internalPlaylistGroupsSelected.filter(x => x.id === item.id)[0]
		let selectedItemsTemp = internalPlaylistGroupsSelected.filter(x => x.id !== item.id)
		selectedItems.position = parseInt(e)
		selectedItemsTemp.push(selectedItems)
		selectedItemsTemp = selectedItemsTemp.sort(function (a, b) {
			return a.position - b.position
		})
		setInternalPlaylistGroupsSelected(selectedItemsTemp)
	}

	const deletePlaylistGroup = item => {
		let selectedItemsTemp = internalPlaylistGroupsSelected.filter(x => x.playlist !== item.playlist)
		selectedItemsTemp = selectedItemsTemp.sort(function (a, b) {
			return a.position - b.position
		})
		setInternalPlaylistGroupsSelected(selectedItemsTemp)

		let removedSelectedItemsTemp = internalPlaylistGroupsRemoved
		let checkItemExist = internalPlaylistGroupsOriginal.filter(x => x.playlist === item.playlist)[0]
		if (checkItemExist) {
			removedSelectedItemsTemp.push(checkItemExist)
			setInternalPlaylistGroupsRemoved(removedSelectedItemsTemp)
		}

		let internalPlaylistTemp = internalPlaylist
		let temp = internalPlaylistTemp.filter(x => x.value === item.playlist)[0]
		if (temp === undefined)
			internalPlaylistTemp.push({
				value: item.playlist,
				label: item.playlist_name
			})

		setInternalPlaylist(internalPlaylistTemp)
	}

	const handleUpdateContainer = (access, newName, newDescription, newPosition, playlistId, isDiplayAlert = true) => {
		let position = parseInt(newPosition)

		const file = DataURIToBlob(photoShow)
		const formData = new FormData()
		formData.append('Photo', file, uuid.v4() + '.jpg')
		formData.append('name', newName)
		formData.append('description', newDescription)
		formData.append('position', position)
		axios
			.put(INTERNAL_CONTAINER_URL + playlistId, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				console.log('handleUpdateContainer: ' + response.status)
				if (response.status === 201) {
					getPlaylist()
					if (isDiplayAlert)
						Alert.alert('Opdater kategori', 'Opdateret', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				} else {
					if (isDiplayAlert)
						Alert.alert('Opdater kategori', 'Kunne ikke opdateres', [
							{ text: 'OK', onPress: () => console.log('OK Pressed') }
						])
				}
			})
			.catch(err => {
				if (isDiplayAlert)
					Alert.alert('Opdater kategori', 'Kunne ikke opdateres', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])

				console.log('handleUpdateContainer: ' + err)
			})
			.finally(() => {
				setLoading(false)
				setModalVisible(false)
				setProcessType('update')
			})
	}

	const isFieldsValid = () => {
		if (name && description) {
			if (name.trim() === '' || description.trim() === '') {
				setMessage({ error: true, message: 'Udfyld venligst alle felter' })
				setLoading(false)
				return false
			}
		} else {
			setMessage({ error: true, message: 'Udfyld venligst alle felter' })
			setLoading(false)
			return false
		}

		if (isNaN(positionState)) {
			setMessage({ error: true, message: 'Positionen skal være numerisk' })
			setLoading(false)
			return false
		}

		return true
	}

	const handleUpdate = async () => {
		setLoading(true)

		if (!isFieldsValid()) return

		const { access } = await getTokens()

		//update internal playlist container
		handleUpdateContainer(access, name, description, positionState, playlistInfo.id)

		internalPlaylistGroupsSelected.map(item => {
			if (item.processType === 'add') {
				//add new playlist group to container
				postPlaylistGroup(item, access)
			} else {
				//update playlist group
				updatePlaylistGroup(item, access)
			}
		})

		internalPlaylistGroupsRemoved.map(item => {
			//delete playlist group
			removePlaylistGroup(item, access)
		})

		getPlaylist()
		setName('')
		setDescription('')
	}

	const postPlaylistGroup = (item, access, internal_container_group = playlistInfo.id) => {
		let playlist = item.playlist
		let description = item.description
		let position = item.position

		axios
			.post(
				INTERNAL_PLAYLIST_GROUP_URL + 'playlist',
				{ playlist, description, internal_container_group, position },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					console.log('ADDED')
				} else {
					console.log('FAILED TO ADD')
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				setModalVisible(false)
				setProcessType('update')
			})
	}

	const updatePlaylistGroup = (item, access) => {
		let playlist = item.playlist
		let description = item.description
		let internal_container_group = playlistInfo.id
		let position = item.position
		axios
			.put(
				INTERNAL_PLAYLIST_GROUP_URL + 'playlist/' + item.id,
				{ playlist, description, internal_container_group, position },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 200) {
					console.log('UPDATED')
				} else {
					console.log('FAILED TO UPDATE')
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				setModalVisible(false)
				setProcessType('update')
			})
	}

	const removePlaylistGroup = (item, access) => {
		axios
			.delete(INTERNAL_PLAYLIST_GROUP_URL + 'playlist/' + item.id, {
				headers: { Authorization: `JWT ${access}` }
			})
			.then(response => {
				if (response.status === 204) {
					console.log('DELETED')
				} else {
					console.log('FAILED TO DELETE')
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				setModalVisible(false)
			})
	}

	const handleSubmit = async () => {
		setLoading(true)

		if (!isFieldsValid()) return

		const { access } = await getTokens()

		//add internal playlist container
		handleAddContainer(access)
	}

	const handleAddContainer = access => {
		let position = parseInt(positionState)

		const file = DataURIToBlob(photoShow)
		const formData = new FormData()
		formData.append('Photo', file, 'image.jpg')
		formData.append('name', name)
		formData.append('description', description)
		formData.append('position', position)
		axios
			.post(INTERNAL_CONTAINER_URL, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				if (response.status === 201) {
					console.log('ADDED')
					Alert.alert('Opret kategori', 'Oprettet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])

					internalPlaylistGroupsSelected.map(item => {
						if (item.processType === 'add') {
							//add new playlist group to container
							postPlaylistGroup(item, access, response.data.id)
						} else {
							//update playlist group
							updatePlaylistGroup(item, access)
						}
					})

					if (position === 0) {
						playlist.map(item => {
							let newPosition = parseInt(item.position) + 1
							console.log('playlists: ' + item.name + '; position: ' + newPosition)
							handleUpdateContainer(access, item.name, item.description, newPosition, item.id, false)
						})
					}

					internalPlaylistGroupsRemoved.map(item => {
						//delete playlist group
						removePlaylistGroup(item, access)
					})

					getPlaylist()
					setName('')
					setDescription('')
				} else {
					console.log('FAILED')
					Alert.alert('Opret kategori', 'Kunne ikke oprettes', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				Alert.alert('Opret kategori', 'Kunne ikke oprettes', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				console.log(err.response.data)
			})
			.finally(() => {
				setLoading(false)
				setModalVisible(false)
			})
	}

	return (
		<View style={trackStyles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					setModalVisible(false)
				}}
			>
				<View style={trackStyles.centeredView}>
					<View style={trackStyles.modalView}>
						<ScrollView>
							<View style={{ flexDirection: 'row' }}>
								<Text style={[{ marginRight: 10, marginTop: 10, color: 'white' }, trackStyles.label]}>
									Navn på kategori
								</Text>
								<Input
									placeholder={'Navn på kategori'}
									value={name}
									onChangeText={text => setName(text)}
									style={{ width: 180 }}
								/>
							</View>
							<View style={{ flexDirection: 'row' }}>
								<Text style={[{ marginRight: 15, marginTop: 10, color: 'white' }, trackStyles.label]}>
									Informativ tekst
								</Text>
								<TextArea
									placeholder={'Informativ tekst'}
									value={description}
									numberOfLines={4}
									onChangeText={text => setDescription(text)}
									style={{ width: 180 }}
								/>
							</View>
							<View style={{ flexDirection: 'row' }}>
								<Text style={[{ marginRight: 63, marginTop: 10, color: 'white' }, trackStyles.label]}>Position</Text>
								<Input
									placeholder={'Position'}
									value={String(positionState)}
									onChangeText={text => setPosition(text)}
									style={{ width: 180 }}
								/>
							</View>
							<PrimaryButton title={'Vælg et billede fra kamerarullen'} onPress={pickImage} />
							{(Photo || playlistInfo) && (
								<Image
									source={{ uri: Photo ? Photo.uri : (playlistInfo ? (playlistInfo.Photo ? playlistInfo.Photo : (Photo ? Photo.uri : null)) : (Photo ? Photo.uri : null)) }}
									style={{ width: 200, height: 200, marginBottom: 10, borderWidth: 1, borderColor: 'gray' }}
								/>
							)}
							<View style={{ flexDirection: 'row' }}>
								<Text style={[{ marginRight: 40, marginTop: 10, color: 'white' }, trackStyles.label]}>Playlist</Text>
								<Select onValueChange={onChangeSelectNewPlaylist} value={newPlaylist} items={internalPlaylist} />
							</View>
							<View
								style={{
									borderBottomColor: 'white',
									borderBottomWidth: 2,
									marginTop: 10
								}}
							/>
							{loadingPlaylistGroup ? (
								<ActivityIndicator size="large" color={colors.accent} />
							) : (
								<FlatList
									data={internalPlaylistGroupsSelected}
									ListEmptyComponent={
										<View>
											<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>
												Ingen afspilningslistegruppe...
											</Text>
										</View>
									}
									renderItem={({ item }) => (
										<View
											style={{ flexDirection: 'row', width: 300, marginTop: 10 }}
											key={'selectPlaylistPosition' + item.id}
										>
											<View style={{ width: 240 }}>
												<Text style={[{ marginTop: 10, color: 'white', marginRight: 20 }, trackStyles.label]}>
													{item.playlist_name}
												</Text>
											</View>
											<View style={{ width: 50, marginRight: 10 }}>
												<Select
													onValueChange={e => {
														onChangePosition(e, item)
													}}
													value={String(item.position)}
													items={positionNumbers}
													width={60}
													key={'selectPosition' + item.id}
												/>
											</View>
											<View style={{ marginRight: 10 }}>
												<Ionicons
													name="trash"
													size={20}
													color={'white'}
													style={{ marginTop: 10 }}
													onPress={() => deletePlaylistGroup(item)}
												/>
											</View>
										</View>
									)}
									showsVerticalScrollIndicator={false}
									showsHorizontalScrollIndicator={false}
									keyExtractor={item => item.id}
								/>
							)}
							<PrimaryButton
								style={{ marginTop: 30, width: 300 }}
								title={processType === 'update' ? 'Opdater kategori' : 'Opret kategori'}
								loading={loading}
								onPress={() => {
									processType === 'update' ? handleUpdate() : handleSubmit()
								}}
							/>
							<SecondaryButton title={'Anuller'} onPress={() => setModalVisible(false)} style={{ width: 300 }} />
						</ScrollView>
						<Text style={{ textAlign: 'center', color: message.error ? 'red' : 'green', marginVertical: 10 }}>
							{message.message}
						</Text>
					</View>
				</View>
			</Modal>
		</View>
	)
}
