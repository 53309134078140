import { Pressable, Text, View } from 'react-native'
import Card from './components/Card'

export default function SearchResultYear({ year, navigation }) {
	return (
		<View>
			{year && (
				<View>
					{year.length > 0 ? (
						<View>
							<View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								{year.map(item => (
									<Pressable onPress={() => navigation.navigate('YearTracks', { year: item })} key={item}>
										<Card image={item.Photo} title={item} />
									</Pressable>
								))}
							</View>
						</View>
					) : (
						<Text style={{ color: 'white' }}>Ingen data...</Text>
					)}
				</View>
			)}
		</View>
	)
}
