import React, { useEffect, useState } from 'react'
import { Text, useWindowDimensions, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'
import { Input } from '../../Components/Forms/Input'

import Loader from '../../Components/Loader/Loader'
import { Heading } from '../../Components/Typography/Typography'
import { colors, INTERNAL_CONTAINER_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import ContainerCard from './components/ContainerCard'

export default function InternalContainerScreen() {
	const api = useAxios()
	const { height } = useWindowDimensions()

	const [loading, setLoading] = useState(true)
	const [containers, setContainers] = useState([])
	const [originalContainers, setOriginalContainers] = useState([])

	const handleSearch = target => {
		let newContainers = containers.filter(x => x.name.toLowerCase().includes(target.nativeEvent.text))
		setContainers(newContainers)
	}

	const onChangeText = text => {
		if (text === '') {
			setContainers(originalContainers)
		}
	}

	useEffect(() => {
		async function boot() {
			const { data } = await api.get(INTERNAL_CONTAINER_URL)
			let newData = data.sort((a, b) => (a.position > b.position ? 1 : -1))
			setContainers(newData)
			setOriginalContainers(newData)
			setLoading(false)
		}
		boot()
	}, [])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, height: height - 80 }}>
			<View style={{ justifyContent: 'center' }}>
				<Input
					style={{ marginTop: 10 }}
					onSubmitEditing={handleSearch}
					placeholder={'Søg efter kategorier eller spillelister'}
					onChangeText={onChangeText}
				/>
			</View>
			<Heading style={{ color: colors.accent }}>Kategorier</Heading>
			<FlatGrid
				itemDimension={200}
				data={containers}
				renderItem={item => <ContainerCard item={item} />}
				ListEmptyComponent={<Text style={{ color: 'white' }}>Ingen data...</Text>}
				keyExtractor={item => item.id}
				style={{ flex: 1 }}
				contentContainerStyle={{ paddingBottom: 90 }}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			/>
		</View>
	)
}
