import { Ionicons } from '@expo/vector-icons'
import axios from 'axios'
import React from 'react'
import { ScrollView, Text, View } from 'react-native'
import { Menu, MenuItem } from 'react-native-material-menu'
import { PLAYLIST_TRACKS_URL } from '../../../Helpers/variables'
import useTokens from '../../../Hooks/useTokens'
import { CreatePlaylistModal } from './CreatePlaylistModal'
import { trackStyles } from './TrackStyles'

export const PlaylistTrackMenu = ({
	track,
	setMenuVisible,
	userPlaylists,
	updateUserPlaylists,
	closeNotifModal,
	openNotifModal
}) => {
	const [playlistVisible, setPlaylistVisible] = React.useState(false)
	const [modalVisible, setModalVisible] = React.useState(false)
	const { getTokens } = useTokens()

	const addToPlaylist = async (playlistId, track) => {
		const { access } = await getTokens()
		axios
			.post(
				PLAYLIST_TRACKS_URL + playlistId,
				{ track },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					openNotifModal('Tilføj til spilleliste', 'Tilføjet')
				} else {
					openNotifModal('Tilføj til spilleliste', 'Kunne ikke tilføje')
				}
			})
			.catch(err => {
				console.log('addToPlaylist: ' + err)
				if (err.message === 'Request failed with status code 400') {
					openNotifModal('Tilføj til spilleliste', 'Nummeret findes allerede i spilleliste')
				}
			})
	}

	return (
		<View key={'playlistviewkey' + track.id}>
			<Menu
				style={trackStyles.menuView}
				key={'playlistmenu' + track.id}
				visible={playlistVisible}
				anchor={
					<MenuItem
						onPress={() => {
							setPlaylistVisible(true)
						}}
					>
						<Text style={trackStyles.menuTextView}>Tilføj til spilleliste</Text>
					</MenuItem>
				}
				onRequestClose={() => setPlaylistVisible(false)}
			>
				<View>
					<Ionicons name="arrow-back" size={25} color={'white'} style={{ marginLeft: 15 }} />
				</View>
				<MenuItem
					key={'playlistkey00'}
					onPress={() => {
						setModalVisible(true)
					}}
				>
					<Text style={[trackStyles.menuTextView, { margin: 15, fontSize: 15, fontWeight: 'bold' }]}>
						Opret ny spilleliste
					</Text>
				</MenuItem>
				<ScrollView style={{ maxHeight: 200 }}>
					{userPlaylists.map(playlist => (
						<MenuItem
							key={'playlistkey' + playlist.id}
							onPress={() => {
								addToPlaylist(playlist.id, track.id)
								setPlaylistVisible(false)
								setMenuVisible(false)
							}}
						>
							<Text style={trackStyles.menuTextView}>{playlist.name}</Text>
						</MenuItem>
					))}
				</ScrollView>
				<View>
					{modalVisible && (
						<CreatePlaylistModal
							modalVisible={modalVisible}
							setModalVisible={setModalVisible}
							updateUserPlaylists={updateUserPlaylists}
						/>
					)}
				</View>
			</Menu>
		</View>
	)
}
