import { useEffect, useState } from 'react'
import { Ionicons } from '@expo/vector-icons'
import { Pressable, ScrollView, Text, View, useWindowDimensions } from 'react-native'
import { PrimaryButton } from '../../Components/Buttons/Button'
import Loader from '../../Components/Loader/Loader'
import {
	SEARCH_ALBUMS_URL,
	SEARCH_ARTISTS_URL,
	SEARCH_CONTAINERS_URL,
	SEARCH_GENRE_URL,
	SEARCH_PLAYLISTS_URL,
	SEARCH_TRACKS_URL,
	SEARCH_YEAR_URL,
	colors
} from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'
import SearchResultAlbum from './SearchResultAlbum'
import SearchResultArtist from './SearchResultArtist'
import SearchResultContainer from './SearchResultContainer'
import SearchResultGenre from './SearchResultGenre'
import SearchResultPlaylist from './SearchResultPlaylist'
import SearchResultTrack from './SearchResultTrack'
import SearchResultYear from './SearchResultYear'
import { SearchFilters } from './components/SearchFilters'

export default function SearchScreen({ route, navigation }) {
	const {width} = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	
	const handleResize = () => {
		setIsWideScreen(width > 700)
	}
	
	useEffect(() => {
		handleResize()
	}, [width])

	const [searchResults, setSearchResults] = useState({
		Albums: [],
		Artists: [],
		Tracks: [],
		Genre: [],
		Year: [],
		Playlists: [],
		Containers: []
	})
	const [loading, setLoading] = useState(true)
	const artistLabel = 'Kunstnere'
	const tracksLabel = 'Sange'
	const albumLabel = 'Albums'
	const yearLabel = 'År'
	const genreLabel = 'Genre'
	const allLabel = 'Alle'
	const playlistLabel = 'Spillelister'
	const categoryLabel = 'Kategorier'
	const requestSong = 'Anmod om sange'

	const [selectedCategory, setSelectedCategory] = useState(undefined)
	const [searchInput, setSearchInput] = useState('')
	const [categoryFilters, setCategoryFilters] = useState([])

	const { isShowSearchBox, setIsShowSearchBox } = useLayout()

	const api = useAxios()

	useEffect(() => {
		async function boot() {
			setLoading(true)
			if (categoryFilters.length > 0) {
				let categoryTemp = categoryFilters[0].name === allLabel ? categoryFilters[1].name : categoryFilters[0].name
				getSearchResults(categoryTemp)
				setSelectedCategory(categoryTemp)
			}
			// setLoading(false)
		}
		boot()
	}, [searchInput, categoryFilters])

	useEffect(() => {
		setLoading(true)
		getSearchResults()
	}, [selectedCategory])

	useEffect(() => {
		setLoading(false)
	}, [])

	const handleSearch = (text, categoryFilters) => {
		setSearchInput(text)
		setCategoryFilters(categoryFilters)
	}

	const getUrlWithYearGenre = url => {
		let yearIndex = categoryFilters.findIndex(x => x.name === yearLabel)
		if (yearIndex > -1) {
			if (searchInput) url = url + '+'
			url = url + categoryFilters[yearIndex].yearRange
		}

		let genreIndex = categoryFilters.findIndex(x => x.name === genreLabel)
		if (genreIndex > -1) {
			if (searchInput) url = url + '+'
			url = url + categoryFilters[yearIndex].genre
		}
		return url
	}

	const getSearchResults = async (categoryTemp = selectedCategory) => {
		let url = ''
		let newSelectedCategory = categoryTemp === allLabel ? categoryFilters[1].name : categoryTemp

		if (newSelectedCategory === tracksLabel) {
			url = SEARCH_TRACKS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		} else if (newSelectedCategory === artistLabel) {
			url = SEARCH_ARTISTS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		} else if (newSelectedCategory === albumLabel) {
			url = SEARCH_ALBUMS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		} else if (newSelectedCategory === genreLabel) {
			url = SEARCH_GENRE_URL
			let genreIndex = categoryFilters.findIndex(x => x.name === genreLabel)
			if (genreIndex > -1) {
				url = url + (categoryFilters[genreIndex].genre === allLabel ? 'all' : categoryFilters[genreIndex].genre)
			}
		} else if (newSelectedCategory === yearLabel) {
			url = SEARCH_YEAR_URL
			let yearIndex = categoryFilters.findIndex(x => x.name === yearLabel)
			if (yearIndex > -1) {
				url = url + categoryFilters[yearIndex].yearRange
			}
		} else if (newSelectedCategory === playlistLabel) {
			url = SEARCH_PLAYLISTS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		} else if (newSelectedCategory === categoryLabel) {
			url = SEARCH_CONTAINERS_URL + searchInput
			// url = getUrlWithYearGenre(url)
		}
		if (url) {
			const { data } = await api.get(url)
			setSearchResults(data)
			setLoading(false)
		}
	}

	const TabLabel = ({ label, icon }) => {
		return (
			<Pressable
				onPress={() => {
					setSelectedCategory(label)
				}}
				style={{
					flexDirection: 'row',
					backgroundColor: selectedCategory === label ? colors.accent : colors.secondary,
					height: 35,
					borderRadius: 15,
					marginLeft: 8
				}}
			>
				<Ionicons
					name={icon}
					style={{ margin: 5 }}
					size={20}
					color={selectedCategory === label ? 'white' : colors.accent}
				/>
				<Text
					style={{
						width: 'auto',
						marginTop: 3,
						color: selectedCategory === label ? 'white' : colors.accent,
						fontSize: 20,
						marginRight: 10
					}}
				>
					{label}
				</Text>
			</Pressable>
		)
	}

	const RequestSongView = () => {
		return (
			<View>
				<View
					style={{
						flexDirection: 'row',
						marginTop: 10,
						justifyContent: 'flex-end',
						paddingRight: 5
					}}
				>
					<View>
						<Text style={{ color: 'white', fontSize: isWideScreen ? 20 : 16 }}>{requestSong}</Text>
					</View>
					<View style={{ marginTop: -15, paddingLeft: 5 }}>
						<PrimaryButton
							style={{ width: isWideScreen ? 80 : 70 }}
							title="Send"
							onPress={() => navigation.navigate('SongRequestScreen')}
						/>
					</View>
				</View>
			</View>
		)
	}

	return (
		<ScrollView style={{ flex: 1, width: '100%' }}>
			<View>
				<View>
					<RequestSongView />
				</View>
				<View
					style={{
						borderColor: 'gray',
						borderBottomWidth: 3,
						borderRadius: 5,
						marginBottom: 15,
						width: '100%'
					}}>
					<SearchFilters
						handleSearch={handleSearch}
						searchInput={searchInput}
						checkedCategoryFilters={categoryFilters}
						isShowSearchBox={isShowSearchBox}
						setIsShowSearchBox={setIsShowSearchBox}
					/>
				</View>
			</View>
			{loading ? (
				<Loader />
			) : (
				<>
				{isWideScreen  ? (
				<ScrollView>
					<View style={{ paddingBottom: 80 }}>
						{/* <View style={{ display: 'flex', height: 'auto' }}> */}
						<View style={{ flexDirection: 'row', marginBottom: 10, marginLeft: 10 }}>
							{categoryFilters.map(
								item => item.name !== allLabel && <TabLabel key={item.name} icon={item.icon} label={item.name} />
							)}
						</View>
						{selectedCategory === tracksLabel && (
							<SearchResultTrack tracks={searchResults.Tracks} mostRelated={searchResults.MostRelated} />
						)}
						{selectedCategory === artistLabel && (
							<SearchResultArtist
								artists={searchResults.Artists}
								navigation={navigation}
								mostRelated={searchResults.MostRelated}
							/>
						)}
						{selectedCategory === albumLabel && (
							<SearchResultAlbum
								albums={searchResults.Albums}
								navigation={navigation}
								mostRelated={searchResults.MostRelated}
							/>
						)}
						{selectedCategory === yearLabel && searchResults.Year && (
							<SearchResultYear year={searchResults.Year.filter(x => x.length === 4)} navigation={navigation} />
						)}
						{selectedCategory === genreLabel && (
							<SearchResultGenre genre={searchResults.Genre} navigation={navigation} />
						)}
						{selectedCategory === playlistLabel && (
							<View>
								<SearchResultPlaylist
									playlist={searchResults.ContainerPlaylists}
									navigation={navigation}
									title={'Kategorier spillelister'}
								/>
								<SearchResultPlaylist
									playlist={searchResults.Playlists}
									navigation={navigation}
									title={'Mine spillelister'}
									isShowNoDataLabel={false}
								/>
							</View>
						)}
						{selectedCategory === categoryLabel && (
							<SearchResultContainer containers={searchResults.Containers} navigation={navigation} />
						)}
					</View>
				</ScrollView>
				) : (
					<View style={{ marginTop: 10 }}>
						<ScrollView horizontal={true} style={{ flexDirection: 'row', marginLeft: 10, height: 50 }}>
									{categoryFilters.map(
										item => item.name !== allLabel && <TabLabel key={item.name} icon={item.icon} label={item.name} />
									)}
						</ScrollView>
						<ScrollView horizontal={false} >
							<View style={{ paddingBottom: 80 }}>
								{selectedCategory === tracksLabel && (
									<SearchResultTrack tracks={searchResults.Tracks} mostRelated={searchResults.MostRelated} />
								)}
								{selectedCategory === artistLabel && (
									<SearchResultArtist
										artists={searchResults.Artists}
										navigation={navigation}
										mostRelated={searchResults.MostRelated}
									/>
								)}
								{selectedCategory === albumLabel && (
									<SearchResultAlbum
										albums={searchResults.Albums}
										navigation={navigation}
										mostRelated={searchResults.MostRelated}
									/>
								)}
								{selectedCategory === yearLabel && searchResults.Year && (
									<SearchResultYear year={searchResults.Year.filter(x => x.length === 4)} navigation={navigation} />
								)}
								{selectedCategory === genreLabel && (
									<SearchResultGenre genre={searchResults.Genre} navigation={navigation} />
								)}
								{selectedCategory === playlistLabel && (
									<View>
										<SearchResultPlaylist
											playlist={searchResults.ContainerPlaylists}
											navigation={navigation}
											title={'Kategorier spillelister'}
										/>
										<SearchResultPlaylist
											playlist={searchResults.Playlists}
											navigation={navigation}
											title={'Mine spillelister'}
											isShowNoDataLabel={false}
										/>
									</View>
								)}
								{selectedCategory === categoryLabel && (
									<SearchResultContainer containers={searchResults.Containers} navigation={navigation} />
								)}
							</View>
						</ScrollView>
					</View>
				)}
				</>
			)}
		</ScrollView>
	)
}
