import { useWindowDimensions, Image, View, Pressable } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

import useLayout from '../../../Hooks/useLayout'
import LinkButton from './components/LinkButton'

export default function Sidebar() {
	const windowHeight = useWindowDimensions().height
	const { isSidebarOpen, toggleSidebar, deviceType } = useLayout()

	return (
		<View
			style={{
				height: windowHeight,
				justifyContent: 'space-between',
				alignItems: 'flex-start'
			}}
		>
			<View style={{ paddingHorizontal: 25 }}>
				<View style={{ paddingHorizontal: 0, marginBottom: 20, marginTop: 10 }}>
					{deviceType !== 1 ? (
						<Image
							source={require('../../../assets/music_mind_logo.png')}
							style={{
								width: 160,
								height: 60
							}}
							resizeMode={'contain'}
						/>
					) : (
						// <View style={{ flexDirection: 'row' }}>
						// 	<Heading style={{ color: colors.accent }}>Music</Heading>
						// 	<Heading>Mind</Heading>
						// </View>
						<Pressable onPress={toggleSidebar}>
							<Ionicons name={'ios-menu'} size={30} color={'#ffffff'} />
						</Pressable>
					)}
				</View>
				<LinkButton open={isSidebarOpen} route="AdminUserScreen" title={'Brugere'} icon={'ios-people'} />
				<LinkButton open={isSidebarOpen} route="AdminUserSoonExpireScreen" title={'Status på udløb'} icon={'time'} />
				<LinkButton
					open={isSidebarOpen}
					route="AdminUserDeactivatedScreen"
					title={'Inaktive brugere'}
					icon={'remove-circle'}
				/>
				<LinkButton open={isSidebarOpen} route="AssignPlaylistUserScreen" title={'Tildel spillelister'} icon={'list'} />
				<LinkButton
					open={isSidebarOpen}
					route="AdminInternalPlaylistContainerScreen"
					title={'Internt miljø'}
					icon={'apps'}
				/>
			</View>
		</View>
	)
}
