import { Ionicons } from '@expo/vector-icons'
import { Pressable } from 'react-native'
import { colors } from '../../../../../Helpers/variables'
import useSound from '../../../../../Hooks/useSound'

export default function ShuffleButton() {
	const { currentPlayingTrack, handleClickShuffle, isShuffleActive } = useSound()
	
	return (
		<Pressable disabled={currentPlayingTrack === null}>
			<Ionicons
				name="shuffle"
				size={20}
				color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
				onPress={() => handleClickShuffle()}
			/>
		</Pressable>
	)
}
