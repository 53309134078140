import { useEffect, useState } from 'react'
import { Text, View, useWindowDimensions } from 'react-native'
import truncateStr from '../../Helpers/truncateStr'
import { PrimaryButton } from '../Buttons/Button'
import { styles } from './PlaylistStyles'

const Playlist = ({
	playlist,
	handleEditPlaylist,
	handleDeletePlaylist,
	buttonTitle = 'Rediger',
	hasDeleteBtn = false,
	...props
}) => {

	const { width, height } = useWindowDimensions();
	const checkLargeScreen = () => {
		return width > 700
	}

	const checkPortraitScreen = () => {
		return (height > 500 && height <= 700 && width <= 400)
	}

	const [isWideScreen, setIsWideScreen] = useState(checkLargeScreen())
	const [isPortrait, setIsPortrait] = useState(checkPortraitScreen())

	const handleResize = () => {
		setIsWideScreen(checkLargeScreen())
		setIsPortrait(checkPortraitScreen())
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	return (
		<View style={styles.playlistRow} {...props}>
			<View style={styles.rowContainer}>
				<Text style={styles.albumTitle}>{truncateStr(playlist.name, 45)}</Text>
			</View>
			<View style={styles.buttonContainer}>
				<PrimaryButton title={buttonTitle} onPress={() => handleEditPlaylist(playlist)}
				/>
			</View>

			{hasDeleteBtn && (
				<View style={styles.buttonContainer}>
					<PrimaryButton title={'Slet'}
						onPress={() => handleDeletePlaylist(playlist)} />
				</View>
			)}
		</View>
	)
}

export default Playlist
