import { useEffect, useState } from 'react'
import { Platform, ScrollView, useWindowDimensions, View } from 'react-native'
import * as Sentry from 'sentry-expo'

import Loader from '../../Components/Loader/Loader'
import UserView from '../../Components/Users/UserView'

import { colors, USERLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'

export default function AdminUserScreen({ navigation }) {
	const api = useAxios()
	const { height } = useWindowDimensions()
	const [userList, setUserList] = useState([])
	const [loading, setLoading] = useState(true)

	const getUserList = async () => {
		await api
			.get(USERLIST_URL)
			.then(({ data }) => {
				setUserList(data)
			})
			.catch(error => {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			})
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		getUserList()
	}, [])

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView>
				<UserView userList={userList} getUserList={getUserList} hasAddUserBtn={true} />
			</ScrollView>
		</View>
	)
}
