import { Image, ImageBackground, Modal, StyleSheet, Text, View } from 'react-native'
import { colors } from '../../Helpers/variables'
import NewFeedScreen from '../../Screens/NewsFeedScreen/NewsFeedScreen'
import { SecondaryButton } from '../Buttons/Button'
import { Heading } from '../Typography/Typography'

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: 'gray',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		minWidth: 350,
		width: '90%',
		maxWidth: 700
		// overflow: 'scroll'
		// minWidth: 200
		// alignItems: 'center',
		// justifyContent: 'center',
	},
	label: {
		marginBottom: 20,
		color: 'white',
		fontSize: 20,
		marginTop: 10
	},
	errorMessage: {
		color: 'red',
		marginBottom: 10
	}
})

export const TherapistModal = ({ modalVisible, setModalVisible, info }) => {
	const fallback = require('../../assets/fallback_cat.png')
	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(false)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<View style={{ justifyContent: 'center', alignItems: 'center' }}>
						<ImageBackground
							blurRadius={20}
							imageStyle={{ borderRadius: 15 }}
							source={info.Photo ? { uri: info.Photo } : fallback}
							style={{ height: 200, width: 200 }}
							resizeMode={'cover'}
						>
							<Image
								source={info.Photo ? { uri: info.Photo } : fallback}
								style={{
									height: 150,
									width: 150,
									borderRadius: 50,
									marginLeft: 25,
									marginTop: 25,
									borderColor: colors.accent,
									borderWidth: 2
								}}
								resizeMode={'cover'}
							/>
						</ImageBackground>
						<Heading>{info.name}</Heading>
						<Text style={{marginTop: 30, color: 'white'}}>{info.description}</Text>
					</View>
					<View style={{ justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 20}}>
						<SecondaryButton title={'Luk'} onPress={() => setModalVisible(false)} style={{ width: 180, margin: 10 }} />
					</View>
				</View>
			</View>
		</Modal>
	)
}
