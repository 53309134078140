import axios from 'axios'
import * as ImagePicker from 'expo-image-picker'
import React, { useState } from 'react'
import { Image, ScrollView, Text, View } from 'react-native'
import { PrimaryButton, SecondaryButton } from '../../Components/Buttons/Button'
import { Input, TextArea } from '../../Components/Forms/Input'
import { Select } from '../../Components/Forms/Select'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { CREATE_PLAYLIST_URL, USER_PLAYLIST_URL } from '../../Helpers/variables'
import useTokens from '../../Hooks/useTokens'
import { Platform, Alert } from 'react-native'
import { DataURIToBlob } from '../../Helpers/convertFile'
import uuid from 'react-native-uuid'

export default function CreatePlaylistScreen({
	setModalVisible = undefined,
	playlistInfo,
	processType = 'add',
	updateUserPlaylists
}) {
	const [name, setName] = useState(playlistInfo ? playlistInfo.name : '')
	const [message, setMessage] = useState({ error: false, message: '' })
	const [description, setDescription] = useState(playlistInfo ? playlistInfo.description : '')
	const [theme, setTheme] = useState(playlistInfo ? playlistInfo.theme : '')
	const [visibility, setVisibility] = useState(playlistInfo ? playlistInfo.visibility : 0)
	const visibilityList = [
		{
			value: 0,
			label: 'Privat'
		},
		{
			value: 1,
			label: 'Intern'
		}
	]

	const [loading, setLoading] = useState(false)
	const [Photo, setPhoto] = useState(null)
	const { getTokens } = useTokens()
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})
	const [photoShow, setPhotoShow] = useState(null)

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			allowsEditing: false,
			aspect: [4, 3],
			quality: 1
		})

		console.log(result)

		if (result.cancelled) {
			return
		}

		let localUri = result.uri
		setPhotoShow(localUri)
		setPhoto(result)

		// if (!result.canceled) {
		// 	let uri = result.assets[0].uri

		// 	if (uri) {
		// 		setPhoto(Platform.OS === 'ios' ? uri.replace('file://', '') : uri)
		// 	} else {
		// 		const extension = uri.substring(uri.lastIndexOf('.') + 1, uri.length)
		// 		const type = result.type + '/' + extension
		// 		const name = 'test'
		// 		setPhoto({ uri, type, name })
		// 	}
		// }
	}

	// const fetchImageFromUri = async uri => {
	// 	const response = await fetch(uri)
	// 	const blob = await response.blob()
	// 	return blob
	// }

	// function DataURIToBlob(dataURI) {
	// 	const splitDataURI = dataURI.split(',')
	// 	const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
	// 	const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

	// 	const ia = new Uint8Array(byteString.length)
	// 	for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

	// 	return new Blob([ia], { type: mimeString })
	// }

	const handleSubmit = async () => {
		setLoading(true)

		if (!name.trim() || !description.trim()) {
			setMessage({ error: true, message: 'Please fill in all fields' })
			setLoading(false)
			return
		}

		const { access } = await getTokens()
		console.log(Photo)

		const file = DataURIToBlob(photoShow)
		const formData = new FormData()
		formData.append('Photo', file, uuid.v4() + '.jpg')
		formData.append('name', name)
		formData.append('description', description)
		formData.append('theme', theme)
		formData.append('visibility', visibility)
		axios
			.post(CREATE_PLAYLIST_URL, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				if (response.status === 201) {
					console.log('ADDED')
					openNotifModal('Opret spilleliste', 'Tilføjet')
					setName('')
					setDescription('')
					setPhoto(null)
					if (updateUserPlaylists) {
						updateUserPlaylists(response.data.id, name, description, visibility, Photo.uri)
					}
				} else {
					console.log('FAILED')
					openNotifModal('Opret spilleliste', 'Kunne ikke tilføje')
				}
				setName('')
				setDescription('')
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const onChangeVisibility = selected => {
		setVisibility(selected)
	}

	const handleUpdate = async () => {
		setLoading(true)

		if (!name.trim() || !description.trim()) {
			setMessage({ error: true, message: 'Please fill in all fields' })
			setLoading(false)
			return
		}

		const { access } = await getTokens()
		const file = DataURIToBlob(photoShow)
		const formData = new FormData()
		formData.append('Photo', file, 'image.jpg')
		formData.append('name', name)
		formData.append('description', description)
		formData.append('theme', theme ? theme : null)
		formData.append('visibility', visibility)
		axios
			.put(USER_PLAYLIST_URL + '/' + playlistInfo.id, formData, {
				headers: { Authorization: `JWT ${access}`, 'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				if (response.status === 201) {
					console.log('UPDATED')
					openNotifModal('Opdater spilleliste', 'Opdater')
					if (updateUserPlaylists) {
						updateUserPlaylists(playlistInfo.id, name, description, visibility, Photo.uri)
					}
					setName('')
					setDescription('')
					setPhoto(null)
				} else {
					console.log('FAILED')
					openNotifModal('Opdater spilleliste', 'Kunne ikke opdater')
				}
			})
			.catch(err => {
				setMessage({ error: true, message: 'Something went wrong' })
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<ScrollView>
			<Input placeholder={'Navn'} onChangeText={text => setName(text)} value={name} />
			<TextArea
				placeholder={'Beskrivelse'}
				numberOfLines={4}
				onChangeText={text => setDescription(text)}
				value={description}
			/>
			<PrimaryButton title={'Vælg et billede fra kamerarullen'} onPress={pickImage} />
			{(Photo || playlistInfo) && (
				<Image
				source={{ uri: Photo ? Photo.uri : (playlistInfo ? (playlistInfo.Photo ? playlistInfo.Photo : (Photo ? Photo.uri : null)) : (Photo ? Photo.uri : null)) }}
					style={{ width: 200, height: 200, marginBottom: 10, borderWidth: 1, borderColor: 'gray' }}
				/>
			)}
			<View style={{ flexDirection: 'row' }}>
				<Text style={{ textAlign: 'center', color: 'white', marginVertical: 15, marginRight: 15 }}>Synlighed</Text>
				<Select onValueChange={onChangeVisibility} value={visibility} items={visibilityList} />
			</View>
			<PrimaryButton
				title={processType === 'add' ? 'Opret Spilleliste' : 'Opdater Spilleliste'}
				loading={loading}
				onPress={() => {
					processType === 'add' ? handleSubmit() : handleUpdate()
				}}
			/>
			{setModalVisible && <SecondaryButton title={'Luk'} onPress={() => setModalVisible(false)} />}
			<Text style={{ textAlign: 'center', color: message.error ? 'red' : 'green', marginVertical: 10 }}>
				{message.message}
			</Text>

			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					setModalVisibleExternal={setModalVisible}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
		</ScrollView>
	)
}
