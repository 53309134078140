import { useState } from 'react'
import { Pressable, View, Text, ActivityIndicator } from 'react-native'
import { colors } from '../../Helpers/variables'
import { Ionicons } from '@expo/vector-icons'

export const PrimaryButton = ({ children, onPress, style, title = '', loading = false, ...props }) => {
	const [border, setBorder] = useState(colors.primary)

	return (
		<Pressable
			style={{
				borderColor: border,
				backgroundColor: colors.accent,
				borderWidth: 2,
				borderRadius: 5,
				justifyContent: 'center',
				alignItems: 'center',
				paddingHorizontal: 15,
				// paddingVertical: 5,
				marginVertical: 10,
				color: 'white',
				minHeight: 35,
				...style
			}}
			onPress={onPress}
			onPressIn={() => setBorder(colors.accent)}
			onPressOut={() => setBorder(colors.primary)}
			{...props}
		>
			{loading ? (
				<ActivityIndicator color={'white'} />
			) : (
				<Text style={{ color: 'white', textAlign: 'center', fontSize: 13 }}>{title}</Text>
			)}
		</Pressable>
	)
}

export const InfoButton = ({ setModalVisible, style, fontSize = 16, size = 30 }) => {
	return (
		<View
			style={{
				alignItems: 'flex-end',
				justifyContent: 'flex-end'
			}}
		>
			<Pressable
				onPress={() => {
					setModalVisible(true)
				}}
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-evenly',
					alignItems: 'center',
					width: size,
					height: size,
					borderWidth: 1,
					borderRadius: 30,
					borderColor: 'white',
					backgroundColor: colors.secondary,
					marginTop: 3,
					marginRight: 3,
					...style
				}}
			>
				<Text
					style={{
						fontWeight: 'bold',
						fontSize: fontSize,
						color: 'white'
					}}
				>
					i
				</Text>
			</Pressable>
		</View>
	)
}

export const SecondaryButton = ({ children, onPress, style, title, loading = false, ...props }) => {
	const [border, setBorder] = useState(colors.primary)

	return (
		<Pressable
			style={{
				borderColor: 'white',
				backgroundColor: colors.primary,
				borderWidth: 2,
				borderRadius: 5,
				paddingVertical: 10,
				paddingHorizontal: 20,
				marginVertical: 10,
				color: 'white',
				...style
			}}
			onPress={onPress}
			onPressIn={() => setBorder(colors.accent)}
			onPressOut={() => setBorder(colors.primary)}
			{...props}
		>
			{loading ? (
				<ActivityIndicator color={'white'} />
			) : (
				<Text style={{ color: 'white', textAlign: 'center' }}>{title}</Text>
			)}
		</Pressable>
	)
}

export const DeleteButton = ({onPress}) => {
	return (
		<Pressable
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-evenly',
				alignItems: 'center',
				width: 30,
				height: 30,
				borderWidth: 1,
				borderRadius: 30,
				borderColor: 'white',
				backgroundColor: colors.secondary,
				marginTop: 3
			}}
			onPress={onPress}
		>
			<Ionicons name="trash" size={16} color={'white'} />
		</Pressable>
	)
}
export const ResetButton = ({ onPress }) => {
	return (
		<View
			style={{
				alignItems: 'flex-start',
				justifyContent: 'flex-start'
			}}
		>
			<Pressable
				onPress={onPress}
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-evenly',
					alignItems: 'center',
					width: 30,
					height: 30,
					borderWidth: 1,
					borderRadius: 30,
					borderColor: 'white',
					backgroundColor: colors.secondary
				}}
			>
				<Ionicons name="refresh-outline" size={16} color={'white'} />
			</Pressable>
		</View>
	)
}

export const TourButton = ({ children, onPress, style, title, loading = false, ...props }) => {
	const [border, setBorder] = useState(colors.primary)

	return (
		<Pressable
			style={{
				borderColor: 'white',
				backgroundColor: 'white',
				borderWidth: 2,
				borderRadius: 5,
				paddingVertical: 10,
				paddingHorizontal: 20,
				marginVertical: 10,
				color: 'green',
				...style
			}}
			onPress={onPress}
			onPressIn={() => setBorder(colors.accent)}
			onPressOut={() => setBorder(colors.primary)}
			{...props}
		>
			{loading ? (
				<ActivityIndicator color={'white'} />
			) : (
				<Text style={{ color: '#72CD63', textAlign: 'center', fontWeight: '700' }}>{title}</Text>
			)}
		</Pressable>
	)
}
