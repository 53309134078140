export function addMinutes(date, minutes) {
	return new Date(date.getTime() + minutes * 60000)
}

export default function convertTimeToMins(timerCount) {
	let endDate = new Date()
	if (timerCount > 0) {
		let timerCountMins = timerCount / 60000
		endDate = addMinutes(endDate, timerCountMins)
	}
	return endDate
}

export function secondsParser(millis) {
	if (isNaN(millis)) return '0:00'
	let minutes = Math.floor(millis / 60000)
	let seconds = ((millis % 60000) / 1000).toFixed(0)
	if (parseInt(seconds) === 60) {
		minutes = minutes + 1
		seconds = 0
	}
	return minutes + ':' + (parseInt(seconds) < 10 ? '0' : '') + seconds
}
export function convertTimeWithSecs(timestamp, timerCountSeconds) {
	if (isNaN(timestamp)) timestamp = 0

	if (timerCountSeconds === undefined) timerCountSeconds = 0

	let hrs = Math.floor(timestamp / 60)
	
	if (hrs < 10) {
		hrs = '0' + hrs
	}

	let minutes = Math.floor(timestamp - hrs * 60)
	if (minutes < 10) {
		minutes = '0' + minutes
	}

	let seconds = timerCountSeconds
	if (seconds === 60) seconds = 0
	if (parseInt(seconds) < 10) {
		seconds = '0' + seconds
	}
	timestamp = hrs + ':' + minutes + ':' + seconds
	return timestamp
}

export const currentTime = () => {
	let date = new Date()
	let currentDate = date.toISOString().split('T')
	return `${currentDate[0]} ${currentDate[1].split('.')[0]}`
}

export const isMonday = (date = new Date()) => date.getDay() === 1
export const isCachedDataValid = data => {
	if (!data.length) return false

	const lastUpdated = data[0].lastUpdated
	const diffHours = Math.floor(Math.abs(lastUpdated - Date.now()) / (60 * 60 * 1000))
	const diffDays = Math.floor(diffHours / 24)

	if (isMonday() && diffHours > 12) {
		return false
	} else if (diffDays > 3) {
		return false
	} else {
		return true
	}
}
