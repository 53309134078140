import { Ionicons } from '@expo/vector-icons'
import { useEffect, useState } from 'react'
import { Pressable, Text, View, useWindowDimensions } from 'react-native'
import { PrimaryButton } from '../../../Components/Buttons/Button'
import { Input } from '../../../Components/Forms/Input'
import { SearchFilterCategory } from '../../../Components/Layouts/SearchFilterCategory'
import { colors } from '../../../Helpers/variables'
import { SearchInput } from '../../../Components/Layouts/SearchInput'

export const SearchFilters = ({
	handleSearch,
	searchInput,
	checkedCategoryFilters,
	isShowSearchBox,
	setIsShowSearchBox
}) => {
	
	const {width} = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width > 700)
	
	const handleResize = () => {
		setIsWideScreen(width > 700)
	}
	
	useEffect(() => {
		handleResize()
	}, [width])

	const [categoryFilters, setCategoryFilters] = useState(checkedCategoryFilters)
	const yearLabel = 'År'
	const genreLabel = 'Genre'
	const searchFilterLabel = 'Søgefiltre'
	const [showSearchFilters, setShowSearchFilters] = useState(true)

	const _handleSearch = (input) => {
		if (categoryFilters.filter(x => x.name !== yearLabel && x.name !== genreLabel).length > 0) {
			if (!input) {
				alert('Søgeindtastning er påkrævet')
				return
			}
		}

		if (
			input ||
			categoryFilters.filter(x => x.name === yearLabel).length > 0 ||
			categoryFilters.filter(x => x.name === genreLabel).length > 0
		) {
			handleSearch(input, categoryFilters)
			// setIsOpened(false)
		}
	}

	const handleChangeFilters = filteredCategories => {
		setCategoryFilters(filteredCategories)
	}

	const getMarginBottomForBorderLine = () => {
		if (showSearchFilters) {
			return isWideScreen ? 90 : 10;
		}
		
		return isWideScreen ? -15 : -30;
	}

	return (
		isShowSearchBox && (
			<View
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: isWideScreen ? 10 : 0
				}}
			>
				<View style={{ flexDirection: 'row', marginLeft: 0 }}>
					<Pressable
						onPress={() => setShowSearchFilters(!showSearchFilters)}
						style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
					>
						<Text style={{ color: 'white', 
								fontSize: isWideScreen ? 30 : 20, 
								textAlign: 'center', 
								marginBottom: 15, 
								fontWeight: 'bold' 
								}}>
							{searchFilterLabel}
						</Text>
						<Ionicons
							name={showSearchFilters ? 'chevron-up-circle-outline' : 'chevron-down-circle-outline'}
							size={isWideScreen ? 26 : 20}
							style={{ marginLeft: isWideScreen ? 20 : 10,
								marginRight: -5,
								marginTop: -10
							}}
							color="white"
						/>
					</Pressable>
					<View>
						<Pressable onPress={() => setIsShowSearchBox(false)}>
							<Ionicons 
								name={'close-outline'} 
								size={isWideScreen ? 30 : 25} 
								style={{ marginLeft: isWideScreen ? 20 : 10, marginTop: 2 }}
								color="white" />
						</Pressable>
					</View>
				</View>
				{showSearchFilters && (
					<>
					<View>
						<SearchInput _handleSearch={_handleSearch} />
					</View>
					<View style={{ marginBottom: 10, width: '100%' }}>
						<SearchFilterCategory handleChangeFilters={handleChangeFilters} />
					</View>
					</>
				)}
			</View>
		)
	)
}
