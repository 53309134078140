import DateTimePicker from '@react-native-community/datetimepicker'
import { useState } from 'react'
import { Text, TouchableHighlight, View } from 'react-native'
import { colors } from '../../Helpers/variables'

export const DateTimePickerInput = ({
	mode = 'date',
	onChange,
	value,
	isDisabled = false,
	label = 'Select date',
	isBirthday = false,
	style,
	...props
}) => {
	const [visible, setVisible] = useState(false)
	const [border, setBorder] = useState(colors.primary)
	const currDate = new Date()
	const [date, setDate] = useState(isBirthday ? new Date(currDate.setFullYear(currDate.getFullYear() - 10)) : currDate)

	const onConfirm = (event, selectedDate) => {
		let newDateStr = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000)
			.toISOString()
			.split('T')[0]
		setVisible(false)
		if (selectedDate) onChange(newDateStr)
		else onChange('')
	}

	return (
		<View style={{marginLeft: 5}}>
			{visible && (
				<DateTimePicker
					testID="dateTimePicker"
					mode={mode}
					style={{
						minWidth: 160,
						...style
					}}
					locale={'en_GB'}
					is24Hour={true}
					minimumDate={new Date()}
					value={date}
					onChange={onConfirm}
					{...props}
				/>
			)}

			<View
				style={{
					marginTop: 10
				}}
			>
				<TouchableHighlight
					onPress={() => {
						setVisible(true)
					}}
					disabled={isDisabled}
					style={{
						borderColor: border,
						width: 130,
						borderColor: colors.primary,
						backgroundColor: '#353C50',
						marginLeft: -15,
						height: 33,
						color: 'white',
						paddingLeft: 10,
						paddingTop: 5
						// ...style
					}}
				>
					<Text style={{ color: 'white' }}>{value}</Text>
					{/* <TextInput
						style={{
							borderColor: border,
							width: 130,
							borderColor: colors.primary,
							backgroundColor: '#353C50',
							marginLeft: -15,
							height: 33,
							color: 'white',
							paddingLeft: 10
							// ...style
						}}
						underlineColorAndroid="transparent"
						onFocus={() => {
							setVisible(true)
							alert(value)
							if (value === '') setDate(new Date())
							else setDate(new Date(value))
							setBorder(colors.accent)
						}}
						onBlur={() => {
							setBorder(colors.primary)
						}}
						placeholderTextColor={'white'}
						autoCapitalize="none"
						clearButtonMode="while-editing"
						value={value}
						showSoftInputOnFocus={false}
						onChange={onConfirm}
						{...props}
					/> */}
				</TouchableHighlight>
			</View>
		</View>
	)
}
