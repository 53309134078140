import React from 'react'
import { ImageBackground, ScrollView, StyleSheet, Text, View } from 'react-native'
import { colors } from '../../Helpers/variables'

const CarouselCardItem = ({ item }) => {
	return (
		<View style={styles.container}>
			<ImageBackground source={{ uri: item.imgUrl }} resizeMode="cover" style={styles.image}>
				<ScrollView style={{ maxWidth: 580, minWidth: 300 }}>
					<Text style={styles.header}>{item.title}</Text>
					<Text style={styles.body}>{item.body}</Text>
				</ScrollView>
			</ImageBackground>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: colors.primary,
		borderRadius: 8,
		padding: 10,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 3
		},
		shadowOpacity: 0.29,
		shadowRadius: 4.65,
		elevation: 7,
		opacity: 0.8,
		borderRadius: 15,
		flex: 1,
		minWidth: 350,
		width: '100%',
		maxWidth: 900
	},
	image: {
		height: '100%'
	},
	header: {
		color: 'white',
		fontSize: 28,
		fontWeight: 'bold',
		paddingLeft: 20,
		paddingTop: 10
	},
	body: {
		color: 'white',
		fontSize: 18,
		paddingLeft: 20,
		paddingLeft: 20,
		paddingRight: 20
	}
})

export default CarouselCardItem
