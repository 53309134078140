import { Modal, StyleSheet, Text, View } from 'react-native'
import { TourButton } from '../../Components/Buttons/Button'

const trackStyles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		elevation: 5,
		zIndex: 999
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		zIndex: 999,
		minWidth: 200,
		alignItems: 'center',
		justifyContent: 'center',
		height: 200,
		width: '90%',
	}
})

export const TourModal = ({
	title = 'Guidet tur igennem appen',
	description = 'Velkommen til Musicmind',
	visible,
	skipTour,
	startTour,
	setTourModal
}) => {
	return (
		<Modal
			animationType="slide"
			visible={visible}
			transparent={true}
			onRequestClose={() => {
				skipTour()
			}}
		>
			<View style={trackStyles.centeredView}>
				<View style={trackStyles.modalView}>
					<Text style={{ marginBottom: 20, color: 'black', fontSize: 30 }}>{title}</Text>
					<Text style={{ marginBottom: 20, color: 'black', fontSize: 20 }}>{description}</Text>
					<View style={{ flexDirection: 'row' }}>
						<TourButton
							title={'Spring guiden over'}
							onPress={() => {
								setTourModal()
								skipTour(true)
							}}
							style={{ width: 180, margin: 10, color: 'black' }}
						/>
						<TourButton
							title={'Start guiden'}
							onPress={() => {
								setTourModal()
								startTour()
							}}
							style={{ width: 180, margin: 10, color: 'black' }}
						/>
					</View>
				</View>
			</View>
		</Modal>
	)
}
