import { Modal, StyleSheet, View } from 'react-native'
import { colors } from '../../Helpers/variables'
import FeedbackScreen from '../../Screens/FeedbackScreen/FeedbackScreen'

const trackStyles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		height: 400
	},
	label: {
		marginBottom: 20,
		color: 'white',
		fontSize: 20,
		marginTop: 10
	},
	errorMessage: {
		color: 'red',
		marginBottom: 10
	}
})

const FeedbackModal = ({ modalVisible, setModalVisible }) => {
	return (
		<View style={trackStyles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					setModalVisible(false)
				}}
			>
				<View style={trackStyles.centeredView}>
					<View style={trackStyles.modalView}>
						<FeedbackScreen setModalVisible={setModalVisible} />
					</View>
				</View>
			</Modal>
		</View>
	)
}

export default FeedbackModal
