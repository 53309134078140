import { Ionicons } from '@expo/vector-icons'
import React, { useEffect, useState } from 'react'
import { FlatList, Pressable, ScrollView, Text, useWindowDimensions, View } from 'react-native'
import Loader from '../../Components/Loader/Loader'
import { colors, COPYPLAYLIST_ASSIGNED_URL, USER_PLAYLIST_URL } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import { trackStyles } from '../TracksScreen/components/TrackStyles'

import { PlaylistCard } from '../../Components/Playlists/PlaylistCards'

import { useIsFocused } from '@react-navigation/native'
import { FlatGrid } from 'react-native-super-grid'
import { ConfirmationModal } from '../../Components/Modals/ConfirmationModal'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { playlistSortByName } from '../../Helpers/sortArray'
import useLayout from '../../Hooks/useLayout'
import { CreatePlaylistModal } from '../TracksScreen/components/CreatePlaylistModal'
import { Platform, Alert } from 'react-native'
import AuthService from '../../Services/AuthService'

export default function MyPlaylistScreen({ navigation }) {
	const [userPlaylists, setUserPlaylists] = useState([])
	const [assignedPlaylists, setAssignedPlaylists] = useState([])
	const [loading, setLoading] = useState(true)
	const { deviceType } = useLayout()
	const [modalVisible, setModalVisible] = useState(false)
	const [playlistInfo, setPlaylistInfo] = useState({})
	const [removePlaylistConfirmModal, setRemovePlaylistConfirmModal] = useState(false)
	const [itemPlaylist, setItemPlaylist] = useState(undefined)
	const [itemCopyPlaylistId, setItemCopyPlaylistId] = useState(undefined)
	const [removeCopyPlaylistConfirmModal, setRemoveCopyPlaylistConfirmModal] = useState(false)

	const api = useAxios()
	// check if screen is focused
	const isFocused = useIsFocused()
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	useEffect(() => {
		isFocused && refreshView()
	}, [isFocused])

	const updateUserPlaylists = (id, name, description, visibility, photo) => {
		let userPlaylistsTemp = userPlaylists.filter(x => x.id !== id)
		userPlaylistsTemp.push({ id: id, name: name, description: description, visibility: visibility, Photo: photo })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	const getUserPlaylist = async () => {
		const result = await api.get(USER_PLAYLIST_URL)
		if (result.status === 200) {
			let user = playlistSortByName(result.data)
			setUserPlaylists(user)
		} else {
			console.log(result.status + ' - ' + result.statusText)
			setUserPlaylists([])
		}
	}

	const getCopyPlaylist = async () => {
		const result = await api.get(COPYPLAYLIST_ASSIGNED_URL)
		if (result.status === 201) {
			let playlist = playlistSortByName(
				result.data.filter(x => x.playlist_name !== undefined),
				false
			)
			setAssignedPlaylists(playlist)
		} else {
			console.log(result.status + ' - ' + result.statusText)
			setAssignedPlaylists([])
		}
	}

	const refreshView = async () => {
		console.log('refresh')
		await getUserPlaylist()
		await getCopyPlaylist()
		setLoading(false)
	}

	const handlePlaylistPress = async (playlistId, photo, title, isTracksRemovable, isTrackPositionChangeable = false, isMyPlaylist = false) => {
		let user = await AuthService.getUser()
		let musicTherapistName = isMyPlaylist ? (JSON.parse(user)).username : undefined
		let copyMyPlaylistLabel = isMyPlaylist ? 'Lavet af Musicmind' : 'Kopieret Musicmind spilleliste'
		navigation.navigate('TracksScreen', { playlistId, photo, title, isTracksRemovable, isTrackPositionChangeable, musicTherapistName, copyMyPlaylistLabel})
	}

	const windowHeight = useWindowDimensions().height

	const handleDeletePlaylist = () => {
		let item = itemPlaylist
		api
			.delete(USER_PLAYLIST_URL + '/' + item.id)
			.then(response => {
				if (response.status === 204) {
					let newUserPlaylist = userPlaylists
					newUserPlaylist = newUserPlaylist.filter(x => x.id !== item.id)
					newUserPlaylist = playlistSortByName(newUserPlaylist)
					setUserPlaylists(newUserPlaylist)
					openNotifModal('Slet ', 'Fjernet')
				} else {
					openNotifModal('Slet spillelister', 'Kunne ikke fjernes')
				}
			})
			.catch(err => {
				openNotifModal('Slet spillelister', 'Kunne ikke fjernes')
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
				setRemovePlaylistConfirmModal(false)
			})
	}

	const handleDeleteCopyPlaylist = () => {
		api
			.delete(COPYPLAYLIST_ASSIGNED_URL + '/' + itemCopyPlaylistId)
			.then(response => {
				if (response.status === 204) {
					let newUserPlaylist = assignedPlaylists
					newUserPlaylist = newUserPlaylist.filter(x => x.id !== itemCopyPlaylistId)
					setAssignedPlaylists(newUserPlaylist)
					openNotifModal('Slet ', 'Fjernet')
				} else {
					openNotifModal('Slet spillelister', 'Kunne ikke fjernes')
				}
			})
			.catch(err => {
				openNotifModal('Slet spillelister', 'Kunne ikke fjernes')
				console.log(err)
			})
			.finally(() => {
				setLoading(false)
				setRemoveCopyPlaylistConfirmModal(false)
			})
	}

	const cancelCallbackRemovePlaylist = () => {
		setRemovePlaylistConfirmModal(false)
	}

	const cancelCallbackRemoveCopyPlaylist = () => {
		setRemoveCopyPlaylistConfirmModal(false)
	}

	// const setDeleteConfirmModal = playlistId => {
	// 	setItemCopyPlaylistId(playlistId)
	// 	setRemoveCopyPlaylistConfirmModal(true)
	// }

	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView style={{ paddingBottom: 90 }}>
				<View style={{ padding: 10, marginBottom: 10, backgroundColor: colors.secondary, borderRadius: 5 }}>
					<Text style={{ color: colors.accent }}>Tildelte spillelister</Text>
				</View>
				<View>
					<FlatGrid
						itemDimension={200}
						data={assignedPlaylists}
						renderItem={item => (
							<PlaylistCard
								key={item.id}
								item={item}
								width={deviceType === 1 ? 230 : 190}
								handlePlaylistPress={handlePlaylistPress}
								setRemoveCopyPlaylistConfirmModal={setRemoveCopyPlaylistConfirmModal}
								hasDeleteButton={true}
								setItemCopyPlaylistId={setItemCopyPlaylistId}
							/>
						)}
						ListEmptyComponent={
							<View>
								<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>Ingen spillelister.</Text>
							</View>
						}
						keyExtractor={item => item.id}
						style={{ flex: 1 }}
						contentContainerStyle={{ paddingBottom: 40 }}
						showsVerticalScrollIndicator={false}
						showsHorizontalScrollIndicator={false}
					/>
				</View>

				<View style={{ padding: 10, marginBottom: 10, backgroundColor: colors.secondary, borderRadius: 5 }}>
					<Text style={{ color: colors.accent }}>Mine spillelister</Text>
				</View>

				<View style={trackStyles.trackList}>
					<FlatList
						data={userPlaylists}
						renderItem={playlist => (
							<View style={trackStyles.trackRow} key={playlist.item.id}>
								<Pressable
									onPress={() =>
										handlePlaylistPress(playlist.item.id, playlist.item.photo ? playlist.item.photo : playlist.item.Photo, playlist.item.name, true, true, true)
									}
									style={trackStyles.songContainer}
								>
									<View>
										<Text style={{ color: 'white', fontSize: 14 }}>{playlist.item.name}</Text>
										<Text style={{ color: 'white', fontSize: 10 }}>{playlist.item.description}</Text>
									</View>
								</Pressable>
								<View style={trackStyles.leftContainer}>
									<Pressable
										onPress={() => {
											setModalVisible(true)
											setPlaylistInfo(playlist.item)
										}}
										style={{ marginLeft: 10 }}
									>
										<Ionicons name="create" size={25} color={'white'} />
									</Pressable>
									<Pressable
										onPress={() => {
											setRemovePlaylistConfirmModal(true)
											setItemPlaylist(playlist.item)
										}}
										style={{ marginLeft: 10 }}
									>
										<Ionicons name="trash" size={25} color={'white'} />
									</Pressable>
								</View>
							</View>
						)}
						ListEmptyComponent={
							<View>
								<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>Ingen spillelister.</Text>
							</View>
						}
						keyExtractor={item => item.id}
						numColumns={1}
						contentContainerStyle={{ paddingBottom: 90 }}
						showsVerticalScrollIndicator={false}
						showsHorizontalScrollIndicator={false}
					/>
				</View>

				{/* <View> */}
				{modalVisible && (
					<CreatePlaylistModal
						modalVisible={modalVisible}
						setModalVisible={setModalVisible}
						playlistInfo={playlistInfo}
						processType={'update'}
						updateUserPlaylists={updateUserPlaylists}
					/>
				)}
				{/* </View> */}

				{removePlaylistConfirmModal && (
					// <View>
					<ConfirmationModal
						handleSubmit={handleDeletePlaylist}
						modalVisible={removePlaylistConfirmModal}
						cancelCallback={cancelCallbackRemovePlaylist}
						description={'Er du sikker pø, at du vil fjerne denne spilleliste?'}
					/>
					// </View>
				)}
				{removeCopyPlaylistConfirmModal && (
					// <View>
					<ConfirmationModal
						handleSubmit={handleDeleteCopyPlaylist}
						modalVisible={removeCopyPlaylistConfirmModal}
						cancelCallback={cancelCallbackRemoveCopyPlaylist}
						description={'Er du sikker pø, at du vil fjerne denne spilleliste?'}
					/>
					// </View>
				)}
				{notifModal.visible && (
					<NotificationModal
						title={notifModal.title}
						description={notifModal.description}
						visible={notifModal.visible}
						closeNotifModal={closeNotifModal}
						isDisappearing={notifModal.isDisappearing}
					/>
				)}
			</ScrollView>
		</View>
	)
}
