import { useState } from 'react'
import { Pressable, View } from 'react-native'
import useSound from '../../../Hooks/useSound'
import { TrackMenu } from '../../TracksScreen/components/TrackMenu'
import Card from './Card'

export default function SearchResultTrackCard({
	track,
	userPlaylists,
	updateUserPlaylists,
	openNotifModal,
	closeNotifModal,
	item,
	index,
	tracks
}) {
	const { handleTrackPlayer, currentPlayingTrack } = useSound()
	const [menuVisible, setMenuVisible] = useState(false)
	return (
		<View
			key={'main' + track.id}
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				height: 165,
				margin: 15
			}}
		>
			<View
				style={{
					alignItems: 'center',
					justifyContent: 'center',
					position: 'absolute',
					width: '100%',
					height: '100%'
				}}
			>
				<Pressable onPress={() => handleTrackPlayer(track, tracks, index)} key={track.id}>
					<Card
						image={track.album_photo}
						title={track.song_title}
						secondTitle={track.artist_name}
						hasTrackMenuBtn={true}
					/>
				</Pressable>
			</View>

			<View style={{ marginTop: -140, marginLeft: 120 }} key={'submaintrack' + track.id}>
				<TrackMenu
					key={'trackmenucaller' + track.id}
					track={track}
					menuVisible={menuVisible}
					setMenuVisible={setMenuVisible}
					isTracksRemovable={false}
					userPlaylists={userPlaylists}
					updateUserPlaylists={updateUserPlaylists}
					openNotifModal={openNotifModal}
					closeNotifModal={closeNotifModal}
					item={item}
					currentPlayingTrack={currentPlayingTrack}
				/>
			</View>
		</View>
	)
}
