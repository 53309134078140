import React, { useState } from 'react'

import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import axios from 'axios'
import { Alert, Image, ImageBackground, Platform, Pressable, Text, TouchableHighlight, View } from 'react-native'
import { NotificationModal } from '../../../Components/Modals/NotificationModal'
import { TherapistModal } from '../../../Components/Modals/TherapistModal'
import { COPYPLAYLIST_ASSIGNED_URL, MUSICTHERAPISTS_URL, colors } from '../../../Helpers/variables'
import useLayout from '../../../Hooks/useLayout'
import useSound from '../../../Hooks/useSound'
import useTokens from '../../../Hooks/useTokens'

export default function PlaylistDetails({
	tracksList,
	hasCopyPlaylistBtn = false,
	edit,
	isTrackPositionChangeable,
	setEdit,
	isRepeatAllTracks = false,
	musicTherapistName,
	musicTherapistId,
	isHideTherapist = false,
	artistName = '',
	saveTracksPosition,
	copyMyPlaylistLabel = 'Lavet af Musicmind',
	...props
}) {
	const { photo, title, tracks } = props
	const { deviceType } = useLayout()

	const fallback = require('../../../assets/fallback_cat.png')
	const logo = require('../../../assets/icon.png')
	const { getTokens } = useTokens()
	const [therapistModal, setTherapistModal] = useState(false)
	const [therapistInfo, setTherapistInfo] = useState(undefined)
	const {
		handleTrackPlayer,
		handleClickShuffle,
		isShuffleActive,
		handleClickRepeat,
		repeatType,
		mediaPlayerAcquisition,
		currentPlayingTrack,
		setIsShuffleActive
	} = useSound()

	// const {
	// 	handleTrackPlayer,
	// 	handleClickShuffle,
	// 	isShuffleActive,
	// 	handleClickRepeat,
	// 	repeatType,
	// 	mediaPlayerAcquisition,
	// 	currentPlayingTrack,
	// 	setIsShuffleActive
	// } = useSound()

	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})

	const { isSidebarOpen } = useLayout()

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const handleCopyPlaylist = async () => {
		let is_allowed_to_be_removed = true
		let playlist = tracksList[0].playlist
		const { access } = await getTokens()

		axios
			.post(
				COPYPLAYLIST_ASSIGNED_URL,
				{ playlist, is_allowed_to_be_removed },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) {
					openNotifModal('Tilføj spilleliste', 'Tilføj')
				} else {
					openNotifModal('Tilføj spilleliste', 'Kunne ikke tilføje ')
				}
			})
			.catch(err => {
				openNotifModal('Tilføj spilleliste', 'Kunne ikke tilføje ')
				console.log(err)
			})
	}

	const getMusicTherapistInfo = async () => {
		const { access } = await getTokens()
		axios
			.get(MUSICTHERAPISTS_URL + musicTherapistId, {
				headers: { Authorization: `JWT ${access}` }
			})
			.then(({ data }) => {
				if (data.length > 0) {
					setTherapistInfo(data[0])
					setTherapistModal(true)
				}
			})
			.catch(error => {
				console.log(error)
			})
		// .finally(() => setLoading(false))
	}

	return (
		<View
			style={{
				flexDirection: deviceType !== 1 || isSidebarOpen ? 'row' : 'column',
				alignItems: deviceType !== 1 ? 'center' : 'flex-start',
				marginBottom: 10
			}}
		>
			<ImageBackground
				blurRadius={20}
				imageStyle={{ borderRadius: 15 }}
				source={photo ? { uri: photo } : fallback}
				style={{ height: 150, width: 150 }}
				resizeMode={'cover'}
			>
				<Image
					source={photo ? { uri: photo } : logo}
					style={{
						height: 100,
						width: 100,
						borderRadius: 50,
						marginLeft: 25,
						marginTop: 25,
						borderColor: colors.accent,
						borderWidth: 2
					}}
					resizeMode={'cover'}
				/>
			</ImageBackground>
			<View style={{ flex: 1, marginLeft: 10, marginTop: deviceType !== 1 ? 0 : 20 }}>
				<Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>{title}</Text>
				<Text style={{ color: 'white', fontSize: 16, marginBottom: 5 }}>{tracks} Numre</Text>
				{isHideTherapist === false ? (
					<View>
						{musicTherapistName ? (
							<TouchableHighlight
								onPress={() => {
									getMusicTherapistInfo()
								}}
							>
								<Text style={{ color: colors.accent, fontSize: 13, fontStyle: 'italic' }}>
									Lavet af: {musicTherapistName}
								</Text>
							</TouchableHighlight>
						) : (
							<Text style={{ color: colors.accent, fontSize: 13, fontStyle: 'italic' }}>{copyMyPlaylistLabel}</Text>
						)}
					</View>
				) : (
					<View>
						<Text style={{ color: colors.accent, fontSize: 13, fontStyle: 'italic' }}>{artistName}</Text>
					</View>
				)}

				<View style={{ flexDirection: 'row', marginTop: 20, alignItems: 'center' }}>
					<Pressable
						disabled={tracksList && tracksList.length == 0}
						onPress={() => {
							if (isRepeatAllTracks && repeatType === 'none') handleClickRepeat('repeatAllTracks')
							else if (!isRepeatAllTracks && repeatType === 'repeatAllTracks') handleClickRepeat('none')
							handleTrackPlayer(tracksList[0].track, tracksList, 0)
							setIsShuffleActive(false)
						}}
					>
						<View
							style={{
								backgroundColor: colors.accent,
								borderRadius: 40,
								paddingVertical: 7,
								paddingHorizontal: 10,
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<Ionicons name="play-circle" size={16} style={{ marginRight: 5 }} color={colors.background} />
							<Text style={{ fontSize: 14, fontWeight: 'bold', color: colors.background }}>Afspil</Text>
						</View>
					</Pressable>
					{hasCopyPlaylistBtn && tracksList.length > 0 && (
						<Pressable onPress={() => handleCopyPlaylist()}>
							<View
								style={{
									backgroundColor: colors.accent,
									borderRadius: 40,
									paddingVertical: 7,
									paddingHorizontal: 10,
									flexDirection: 'row',
									alignItems: 'center',
									marginLeft: 10
								}}
							>
								<Ionicons name="copy" size={16} style={{ marginRight: 5 }} color={colors.background} />
								<Text style={{ fontSize: 14, fontWeight: 'bold', color: colors.background }}>Kopier</Text>
							</View>
						</Pressable>
					)}
					{mediaPlayerAcquisition.isShuffleBtnVisible && (
						<Pressable disabled={currentPlayingTrack === null}>
							<Ionicons
								name="shuffle"
								size={26}
								color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
								style={{ marginHorizontal: 5 }}
								onPress={() => handleClickShuffle()}
							/>
						</Pressable>
					)}
					{mediaPlayerAcquisition.isRepeatBtnVisible && (
						<Pressable>
							{repeatType === 'one' ? (
								<MaterialIcons
									name="repeat-one"
									size={26}
									color={currentPlayingTrack === null ? colors.primary : colors.accent}
									style={{ marginHorizontal: 5 }}
									onPress={() => handleClickRepeat(isRepeatAllTracks ? 'repeatAllTracks' : 'none')}
								/>
							) : (
								<Ionicons
									name="ios-repeat-sharp"
									size={26}
									color={currentPlayingTrack === null ? colors.primary : repeatType === 'all' ? colors.accent : 'white'}
									style={{ marginHorizontal: 5 }}
									onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
								/>
							)}
						</Pressable>
					)}

					{isTrackPositionChangeable &&
						(!edit ? (
							<Pressable onPress={() => setEdit(true)}>
								<View
									style={{
										backgroundColor: colors.accent,
										borderRadius: 40,
										paddingVertical: isSidebarOpen ? 7 : 10,
										paddingHorizontal: 5,
										marginHorizontal: 10,
										flexDirection: 'row',
										// alignItems: 'center'

									}}
								>
									<Ionicons name="pencil-sharp" size={isSidebarOpen ? 18 : 13} style={{ marginRight: 3 }} color={colors.background} />
									<Text style={{ fontSize: isSidebarOpen ? 16 : 13, fontWeight: 'bold', color: colors.background, flexWrap: 'wrap' }}>
										Rediger Spilleliste
									</Text>
								</View>
							</Pressable>
						) : (
							<Pressable onPress={() => {
								setEdit(false)
								saveTracksPosition()
							}}>
								<View
									style={{
										backgroundColor: colors.accent,
										borderRadius: 40,
										paddingVertical: 7,
										paddingHorizontal: 20,
										marginHorizontal: 10,
										flexDirection: 'row',
										alignItems: 'center'
									}}
								>
									<Ionicons name="pencil-sharp" size={18} style={{ marginRight: 5 }} color={colors.background} />
									<Text style={{ fontSize: 16, fontWeight: 'bold', color: colors.background }}>Save</Text>
								</View>
							</Pressable>
						))}
				</View>
			</View>
			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
			{therapistModal && (
				<TherapistModal modalVisible={therapistModal} setModalVisible={setTherapistModal} info={therapistInfo} />
			)}
		</View>
	)
}
