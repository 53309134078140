import { Platform } from 'react-native'
import useFiles from '../../Hooks/useFiles'
import db from '../database'
import { captureException } from '../sentryLog'
import { playlistSortByPosition } from '../sortArray'
import { currentTime } from '../timeConverter'

export const addDataToContainersTable = data => {
	if (Platform.OS !== 'web') {
		const { downloadPhoto } = useFiles()
		const timeToBeUpdated = currentTime()

		db.transaction(tx => {
			for (const row of data) {
				let photo = null
				if (row.Photo) photo = 'container_' + row.id + '.' + row.Photo ? row.Photo.split('.')[3].split('?')[0] : null
				tx.executeSql(
					'INSERT OR REPLACE INTO containers (id, Photo, name, description, department, lastCached) VALUES (?,?,?,?,?,?)',
					[row.id, photo, row.name, row.description, row.department, timeToBeUpdated],
					(_, res) => console.log(res.rowsAffected ? 'Added to databse' : 'Error occured while adding to databse'),
					error => captureException(error)
				)
				downloadPhoto('container', row.id, row.Photo)
					.then(
						() => {},
						err => console.log(err)
					)
					.catch(err => console.log(err))
			}
		})
	}
}

export const retrieveDataFromContainersTable = async () => {
	return new Promise((resolve, reject) => {
		db.transaction(tx => {
			tx.executeSql(
				'select * from containers',
				[],
				(_, { rows: { _array } }) => {
					resolve(playlistSortByPosition(_array))
				},
				(cb, err) => {
					console.log(err)
					reject(err)
				}
			)
		})
	})
}
