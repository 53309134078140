import React, { useEffect, useState } from 'react'
import { Switch, Text, View } from 'react-native'
import { colors, TIMER_ACQUISITION_URL } from '../../../../../Helpers/variables'
import useAxios from '../../../../../Hooks/useAxios'
import { convertTimeWithSecs } from '../../../../../Helpers/timeConverter'
import { Ionicons } from '@expo/vector-icons'
import TimerModal from './TimerModal'
import useTimer from '../../../../../Hooks/useTimer'

export default function TimerControls() {
	const api = useAxios()
	const { timerProperties, setTimerProperties, handleTimerChange } = useTimer()

	const [modalVisible, setModalVisible] = useState(false)
	const [loading, setLoading] = useState(true)

	const getTimerAcquisition = async () => {
		const { data } = await api.get(TIMER_ACQUISITION_URL)
		if (data.length > 0) {
			setTimerProperties({
				isTimerChecked: data[0].timer_enabled_bool,
				timerMinutes: data[0].timer_value_integer,
				timerMinutesOriginal: data[0].timer_value_integer,
				isLoaded: true,
				countdownTimer: convertTimeWithSecs(data[0].timer_value_integer, 0)
			})
			handleTimerChange(data[0].timer_enabled_bool)
		} else
			setTimerProperties({
				isTimerChecked: timerProperties.isTimerChecked,
				timerMinutes: timerProperties.timerMinutes,
				timerMinutesOriginal: timerProperties.timerMinutesOriginal,
				isLoaded: true,
				countdownTimer: convertTimeWithSecs(timerProperties.timerMinutes, timerProperties.timerCountSeconds)
			})

		setLoading(false)
	}

	useEffect(() => {
		getTimerAcquisition()
		setLoading(false)
	}, [])


	return loading ? (
		<View />
	) : (
		<View style={{ flexDirection: 'row' }}>
			<Switch
				style={{ fontSize: 25 }}
				trackColor={{ false: '#adadad', true: colors.secondary }}
				thumbColor={timerProperties.isTimerChecked ? colors.accent : '#ffffff'}
				onValueChange={() => {
					if (timerProperties.timerMinutes !== timerProperties.timerMinutesOriginal)
						handleTimerChange(!timerProperties.isTimerChecked)
					else handleTimerChange()
				}}
				value={timerProperties.isTimerChecked}
			/>
			<View style={{ width: 120 }}>
				<Text style={{ color: 'white', marginLeft: 10, marginTop: 5 }}>Timer: {timerProperties.countdownTimer}</Text>
			</View>
			{!timerProperties.isTimerChecked && (
				<Ionicons
					name="create"
					size={25}
					color={'white'}
					style={{ marginLeft: 10 }}
					onPress={() => {
						setModalVisible(true)
					}}
				/>
			)}
			{modalVisible && (
				<TimerModal
					setModalVisible={setModalVisible}
					modalVisible={modalVisible}
					timerProperties={timerProperties}
					setTimerProperties={setTimerProperties}
				/>
			)}
		</View>
	)
}
