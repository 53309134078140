import { useEffect, useState } from 'react'
import { ImageBackground, Platform, Pressable, View } from 'react-native'
import { InfoButton } from '../../../Components/Buttons/Button'
import { Popup } from '../../../Components/Playlists/Popup'
import useFiles from '../../../Hooks/useFiles'

export default function CategoryCard({ item, wasDataCached, navigate, deviceType }) {
	const width = deviceType === 1 ? 240 : 190

	const { getPhoto, isImage } = useFiles()

	const [image, setImage] = useState(item ? item.Photo : null)
	const fallback = require('../../../assets/fallback_cat.png')
	const [modalVisible, setModalVisible] = useState(false)

	useEffect(() => {
		if (Platform.OS !== 'web' && wasDataCached) {
			if (isImage(item.Photo)) {
				setImage(getPhoto(item.Photo))
			} else {
				setImage(null)
			}
		}
	}, [])

	return (
		<Pressable onPress={() => navigate('PlaylistScreen', { categoryId: item.id, hasCopyPlaylistBtn: true })}>
			<View
				style={{
					width: width,
					borderRadius: 10,
					marginBottom: 15,
					marginHorizontal: 15,
					overflow: 'hidden'
				}}
			>
				<ImageBackground
					style={{ width: width, height: width, borderRadius: 10, overflow: 'hidden' }}
					resizeMode="cover"
					onError={() => console.log('Error loading image...')}
					source={image ? { uri: image } : fallback}
				>
					{item.description && <InfoButton size={24} fontSize={16} setModalVisible={setModalVisible} />}
				</ImageBackground>
			</View>
			{modalVisible && (
				<Popup modalVisible={modalVisible} setModalVisible={setModalVisible} description={item.description} />
			)}
		</Pressable>
	)
}
