import { TextInput, TouchableHighlight, View } from 'react-native'
import React, { useState } from 'react'
import { colors } from '../../Helpers/variables'
import { formatDate } from '../../Helpers/convertDate'
import { DatePickerModal } from 'react-native-paper-dates'

export const DateTimePickerWeb = ({
	style,
	onChange,
	value,
	isDisabled = false,
	label = 'Select date',
	isBirthday = false,
	...props
}) => {
	const [visible, setVisible] = useState(false)
	const [border, setBorder] = useState(colors.primary)
	const currDate = new Date()
	const [date, setDate] = useState(isBirthday ? new Date(currDate.setFullYear(currDate.getFullYear() - 10)) : currDate)

	const onDismiss = () => {
		setVisible(false)
	}

	const onConfirm = params => {
		setVisible(false)
		if (params.date) onChange(formatDate(params.date))
		else onChange('')
	}

	return (
		<View>
			<DatePickerModal
				locale="en"
				mode="single"
				visible={visible}
				onDismiss={() => onDismiss()}
				onConfirm={e => {
					onConfirm(e)
				}}
				date={date}
				label={label}
				// startYear={1930}
				// endYear={2012}
				// validRange={{
				//   startDate: new Date(2021, 1, 2),  // optional
				//   endDate: new Date(), // optional
				//   disabledDates: [new Date()] // optional
				// }}
				// onChange={} // same props as onConfirm but triggered without confirmed by user
				// saveLabel="Save" // optional
				// saveLabelDisabled={true} // optional, default is false
				// uppercase={false} // optional, default is true
				animationType="fade" // optional, default is 'slide' on ios/android and 'none' on web
				// startYear={2000} // optional, default is 1800
				// endYear={2100} // optional, default is 2200
				// closeIcon="close" // optional, default is "close"
				// editIcon="pencil" // optional, default is "pencil"
				// calendarIcon="calendar" // optional, default is "calendar"
				// {...props}
			/>
			<View
				style={{
					marginTop: 10
				}}
			>
				<TouchableHighlight onPress={() => setVisible(true)} disabled={isDisabled}>
					<TextInput
						style={{
							borderColor: border,
							backgroundColor: colors.primaryInputColor,
							borderWidth: 2,
							borderRadius: 5,
							paddingVertical: 10,
							paddingHorizontal: 20,
							color: 'white',
							width: '100%',
							height: 40,
							...style
						}}
						underlineColorAndroid="transparent"
						onFocus={() => {
							setBorder(colors.accent)
							setVisible(true)
						}}
						onBlur={() => setBorder(colors.primary)}
						placeholderTextColor={'#cdcdcd'}
						autoCapitalize="none"
						clearButtonMode="while-editing"
						value={value}
						showSoftInputOnFocus={false}
						{...props}
					/>
				</TouchableHighlight>
			</View>
		</View>
	)
}
