import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import { Pressable } from 'react-native'
import { colors } from '../../../../../Helpers/variables'
import useSound from '../../../../../Hooks/useSound'

export default function RepeatButton() {
	const { currentPlayingTrack, handleClickRepeat, repeatType } = useSound()

	
	return (
		<Pressable>
			{repeatType === 'one' ? (
				<MaterialIcons
					name="repeat-one"
					size={20}
					color={currentPlayingTrack === null ? colors.secondary : colors.accent}
					onPress={() => handleClickRepeat('none')}
				/>
			) : (
				<Ionicons
					name="ios-repeat-sharp"
					size={20}
					color={currentPlayingTrack === null ? colors.secondary : repeatType === 'all' ? colors.accent : 'white'}
					onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
				/>
			)}
		</Pressable>
	)
}
