import React from 'react'
import { Text, View } from 'react-native'

export default function OfflineBar() {
	return (
		<View style={{ textAlign: 'center', backgroundColor: 'red', width: '100%' }}>
			<Text style={{ color: 'white', fontWeight: 'bold' }}>Du er offline. Tjek dit internet.</Text>
		</View>
	)
}
