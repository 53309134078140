import { useEffect, useState } from 'react'
import { Modal, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import { PrimaryButton, SecondaryButton } from '../../Components/Buttons/Button'
import { colors } from '../../Helpers/variables'

export const ConfirmationModal = ({
	modalVisible,
	description,
	handleSubmit,
	cancelCallback,
	titleCancel = 'Anuller',
	titleSubmit = 'Slet'
}) => {
	const {width, height} = useWindowDimensions();
	const checkLargeScreen = () => {
		return width > 700
	}

	const checkPortraitScreen = () => {
		return (height > 500 && height <= 700 && width <= 400)
	}

	const checkLandscapeScreen = () => {
		return (width > 500 && width <= 700 && height <= 400)
	}

	const [isWideScreen, setIsWideScreen] = useState(checkLargeScreen())
	const [isPortrait, setIsPortrait] = useState(checkPortraitScreen())
	const handleResize = () => {
		setIsWideScreen(checkLargeScreen())
		setIsPortrait(checkPortraitScreen())
	}

	useEffect(() => {
		handleResize()
	}, [width, height])

	const trackStyles = StyleSheet.create({
		centeredView: {
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		modalView: {
			margin: 20,
			backgroundColor: colors.secondary,
			borderRadius: 20,
			padding: 35,
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 2
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			width: isPortrait ? '90%' : isWideScreen ? '40%' : '70%',
			alignItems: 'center',
			justifyContent: 'center',
			height: 200
		}
	})

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				cancelCallback()
			}}
		>
			<View style={trackStyles.centeredView}>
				<View style={trackStyles.modalView}>
					<Text style={{ marginBottom: 20, color: 'white', fontSize: 20 }}>{description}</Text>
					<View style={{ flexDirection: 'row' }}>
						<SecondaryButton title={titleCancel} onPress={() => cancelCallback()} style={{ width: isPortrait ? 150: 180, margin: 10 }} />
						<PrimaryButton title={titleSubmit} onPress={() => handleSubmit()} style={{ width: isPortrait ? 150: 180, margin: 10 }} />
					</View>
				</View>
			</View>
		</Modal>
	)
}
