import { View } from 'react-native'
import { Heading } from '../../Components/Typography/Typography'
import { colors } from '../../Helpers/variables'
import CarouselCards from '../../Components/Carousel/CarouselCards'

export default function NewFeedScreen({ navigation, newsFeed }) {
	return (
		<View>
			<Heading style={{ color: colors.accent }}>Nyheder</Heading>
			<CarouselCards data={newsFeed} />
		</View>
	)
}
