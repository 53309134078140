import { Ionicons } from '@expo/vector-icons'
import { View, TouchableHighlight } from 'react-native'

export const MusicTherapistsMenu = ({ handleTherapistMenu }) => {
	return (
		<View style={{ alignItems: 'center', justifyContent: 'center' }}>
			<TouchableHighlight
				onPress={() => {
					handleTherapistMenu()
				}}
			>
				<View style={{ flexDirection: 'row' }}>
					<Ionicons name="ios-people-outline" style={{ marginLeft: 15 }} size={34} color="white" />
				</View>
			</TouchableHighlight>
		</View>
	)
}
