import { Platform } from 'react-native'
import useFiles from '../../Hooks/useFiles'
import db from '../database'
import { captureException } from '../sentryLog'
import { playlistSortByPosition } from '../sortArray'
import { currentTime } from '../timeConverter'

export const addDataToPlaylistsTable = data => {
	if (Platform.OS !== 'web') {
		const { downloadPhoto } = useFiles()
		const timeToBeUpdated = currentTime()

		db.transaction(tx => {
			for (const row of data) {
				let photo = 'playlist_' + row.id + '.' + row.Photo.split('.')[3].split('?')[0]
				tx.executeSql(
					'INSERT OR REPLACE INTO playlists (id, playlist, playlist_name, description, container_group, Photo, position, lastCached, music_therapist, music_therapist_name) VALUES (?,?,?,?,?,?,?,?,?,?)',
					[
						row.id,
						row.playlist,
						row.playlist_name,
						row.description,
						row.container_group,
						photo,
						row.position,
						timeToBeUpdated,
						row.music_therapist,
						row.music_therapist_name
					],
					(_, res) => console.log(res.rowsAffected ? 'Added to database' : 'Error occured while adding to database'),
					error => captureException(error)
				)
				downloadPhoto('playlist', row.id, row.Photo)
					.then(
						() => {},
						err => console.log(err)
					)
					.catch(err => console.log(err))
			}
		})
	}
}

export const retrieveDataFromPlaylistsTable = async container_group => {
	return new Promise((resolve, reject) => {
		db.transaction(tx => {
			tx.executeSql(
				'select * from playlists WHERE container_group = ?',
				[container_group],
				(_, { rows: { _array } }) => {
					resolve(playlistSortByPosition(_array, false))
				},
				(cb, err) => {
					console.log(err)
					reject(err)
				}
			)
		})
	})
}
