import { Modal, StyleSheet, Text, View } from 'react-native'
import { SecondaryButton } from '../../Components/Buttons/Button'
import { colors } from '../../Helpers/variables'
import { useEffect } from 'react'

const trackStyles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		elevation: 5,
		zIndex: 999
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		zIndex: 999,
		minWidth: 200,
		alignItems: 'center',
		justifyContent: 'center',
		height: 200
	}
})

export const NotificationModal = ({ title, description, visible, closeNotifModal, setModalVisibleExternal, isDisappearing }) => {

	useEffect(() => {
		if (isDisappearing) {
			const timeoutId = setTimeout(() => {
				closeNotifModal()
				if (setModalVisibleExternal) setModalVisibleExternal(false)
				clearTimeout(timeoutId)
			}, 5000)
		}
	}, [])


	return (
		<Modal
			animationType="slide"
			visible={visible}
			transparent={true}
			onRequestClose={() => {
				closeNotifModal()
				if (setModalVisibleExternal) setModalVisibleExternal(false)
			}}
		>
			<View style={trackStyles.centeredView}>
				<View style={trackStyles.modalView}>
					<Text style={{ marginBottom: 20, color: 'white', fontSize: 30 }}>{title}</Text>
					<Text style={{ marginBottom: 20, color: 'white', fontSize: 20 }}>{description}</Text>
					<View style={{ flexDirection: 'row' }}>
						{
							!isDisappearing &&
							<SecondaryButton
								title={'OK'}
								onPress={() => {
									closeNotifModal()
									if (setModalVisibleExternal) setModalVisibleExternal(false)
								}}
								style={{ width: 180, margin: 10 }}
							/>
						}
					</View>
				</View>
			</View>
		</Modal>
	)
}
