import { Image, ImageBackground, Pressable, Text, View } from 'react-native'
import { StyleSheet } from 'react-native'
import { colors } from '../../../Helpers/variables'


const Therapist = ({ therapist, handleViewTherapist, index, ...props }) => {
	const fallback = require('../../../assets/fallback_cat.png')
	return (
		<Pressable onPress={() => handleViewTherapist(therapist)}>
			<View
				style={{
					width: 200,
					borderRadius: 10,
					backgroundColor: colors.secondary,
					marginBottom: 15,
					marginHorizontal: 15
				}}
			>
				<ImageBackground
					source={therapist.Photo ? { uri: therapist.Photo } : fallback}
					resizeMode="cover"
					imageStyle={{ borderRadius: 10 }}
					onError={() => console.log('Error loading image ...')}
					style={{
						width: 200,
						height: 200
					}}
					blurRadius={20}
				>
					<Image
						source={therapist.Photo ? { uri: therapist.Photo } : fallback}
						style={{
							height: 150,
							width: 150,
							borderRadius: 50,
							marginLeft: 25,
							marginTop: 25,
							borderColor: colors.accent,
							borderWidth: 2
						}}
						resizeMode={'cover'}
					/>
				</ImageBackground>

				<View style={{ marginVertical: 20, alignItems: 'center', height: 40 }}>
					<Text
						style={{
							fontSize: 15,
							fontWeight: 'bold',
							color: 'white',
						}}
					>
						{therapist.name}
					</Text>
				</View>
			</View>
		</Pressable>
		
	)
}

export default Therapist
