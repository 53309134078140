import React, { useLayoutEffect, useState } from 'react'
import { SafeAreaView, View, useWindowDimensions } from 'react-native'
import { colors } from '../../Helpers/variables'
import Sidebar from './Sidebar/Sidebar'
import { Platform } from 'react-native'

export default function MainLayout({ children }) {
	const { height } = useWindowDimensions()
	const [padding, setPadding] = useState(0)
	
	useLayoutEffect(() => {
		if (Platform.OS === 'android') setPadding(15)
		if (Platform.OS === 'ios') setPadding(30)
		if (Platform.OS === 'web') setPadding(0)
	}, [])

	return (
		<View style={{
			backgroundColor: colors.primary,
			paddingTop: padding + 10,
			flex: 1
		}}>
			<View style={{ flexDirection: 'row' }}>
				<Sidebar />
				<View style={{ minHeight: height, paddingRight: 10, flex: 2 }}>{children}</View>
			</View>
		</View>
	)
}
