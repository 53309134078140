import React, { useState } from 'react'
import { Text, useWindowDimensions, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'
import { colors } from '../../Helpers/variables'
import useLayout from '../../Hooks/useLayout'
import { ResetButton } from '../Buttons/Button'
import { Input } from '../Forms/Input'
import { PlaylistCard } from './PlaylistCards'
import { ScrollView } from 'react-native'
import { Platform } from 'react-native'

const PlaylistView = ({ playlists, wasDataCached, setShouldReset, handlePlaylistPress, hideResetBtn = false }) => {
	const windowHeight = useWindowDimensions().height
	const { deviceType } = useLayout()
	const [newPlaylist, setNewPlaylist] = useState(playlists)

	const handleSearch = target => {
		let newPlaylistTemp = newPlaylist.filter(x =>
			x.playlist_name.toLowerCase().includes(target.nativeEvent.text.toLowerCase())
		)
		setNewPlaylist(newPlaylistTemp)
	}

	const onChangeText = text => {
		if (text === '') {
			setNewPlaylist(playlists)
		}
	}

	return (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView>
				{!hideResetBtn && (
					<View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
						<ResetButton style={{ width: 100 }} onPress={() => setShouldReset(true)} />
						<Input
							style={{ marginTop: 10, marginLeft: 10, flexGrow: 1 }}
							onSubmitEditing={target => handleSearch(target)}
							placeholder={'Søg spilleliste'}
							onChangeText={text => onChangeText(text)}
						/>
					</View>
				)}
				<FlatGrid
					itemDimension={206}
					data={newPlaylist}
					renderItem={item => (
						<PlaylistCard
							wasDataCached={wasDataCached}
							item={item}
							width={deviceType === 1 ? 230 : 190}
							handlePlaylistPress={handlePlaylistPress}
						/>
					)}
					ListEmptyComponent={
						<View>
							<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>Ingen spillelister.</Text>
						</View>
					}
					keyExtractor={item => item.id}
					style={{ flex: 1 }}
					contentContainerStyle={{ paddingBottom: 90 }}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
				/>
			</ScrollView>
		</View>
	)
}

export default React.memo(PlaylistView)
