import { Ionicons } from '@expo/vector-icons'
import React from 'react'
import { FlatList, ScrollView, Text, View } from 'react-native'
import DraggableFlatList, { ScaleDecorator } from 'react-native-draggable-flatlist'

import {
	ListItem,
	ThemeProvider,
	theme
} from "react-native-design-system"
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { colors } from '../../../Helpers/variables'
import Track from './Track'
import { trackStyles } from './TrackStyles'
import PlaylistDetails from './PlaylistDetails'

export default function TrackList({
	tracksList,
	photo,
	title,
	isTracksRemovable,
	setTrackList,
	userPlaylists = [],
	isTrackPositionChangeable,
	navigation,
	updateUserPlaylists,
	openNotifModal,
	closeNotifModal,
	edit,
	setEdit,
	tracks,
	hasCopyPlaylistBtn,
	musicTherapistName,
	musicTherapistId,
	saveTracksPosition,
	copyMyPlaylistLabel,
	isRepeatAllTracks = false,
	isMultipleTrackList = false,
	isHidePlaylistDetails = true,
	...props
}) {

	const TrackListView = () => (
		<ScrollView>
			<FlatList
				data={tracksList}
				renderItem={({ item, index }) => (
					<View style={trackStyles.trackList}>
						<Track
							edit={edit}
							track={item.track}
							tracksList={tracksList}
							playlistTrackId={item.id}
							playlistId={item.playlist}
							isTracksRemovable={isTracksRemovable}
							setTrackList={setTrackList}
							userPlaylists={userPlaylists}
							navigation={navigation}
							updateUserPlaylists={updateUserPlaylists}
							openNotifModal={openNotifModal}
							closeNotifModal={closeNotifModal}
							isRepeatAllTracks={isRepeatAllTracks}
							item={item}
							trackIndex={index}
						/>
					</View>
				)}
				keyExtractor={item => item.id + new Date()}
				style={{ flex: 1 }}
				contentContainerStyle={{ paddingBottom: 40 }}
				showsVerticalScrollIndicator={true}
				showsHorizontalScrollIndicator={false}
				ListHeaderComponent={Header}
				scrollEnabled={true}
			/>
		</ScrollView>
	)

	const Header = () => {
		if (isHidePlaylistDetails)
			return null
		
		return (<PlaylistDetails
			photo={photo}
			title={title}
			tracks={tracksList.length}
			tracksList={tracksList}
			hasCopyPlaylistBtn={hasCopyPlaylistBtn}
			edit={edit}
			setEdit={setEdit}
			isTrackPositionChangeable={isTrackPositionChangeable}
			musicTherapistName={musicTherapistName}
			musicTherapistId={musicTherapistId}
			saveTracksPosition={saveTracksPosition}
			copyMyPlaylistLabel={copyMyPlaylistLabel}
		/>)
	}

	const handleMarkAsCompleted = (key) => {
		setTrackList((prevData) => {
			prevData.map((item) => {
				if (item.id === id) {
					item.isCompleted = !item.isCompleted;
				}
				return item;
			});
		});
	};

	const renderItem = ({ item, drag, isActive }) => {

		return (
			<ScaleDecorator>
				<ListItem
					size="lg"
					onLongPress={drag}
					disabled={isActive}
					background={isActive ? colors.accent : colors.primary}
					onPress={() => handleMarkAsCompleted(item.id)}
					leftIcon={
						<Ionicons name="move" size={26} color={'white'} style={{ marginHorizontal: 10 }} />
					}
					textStyle={{
						textDecorationLine: item.isCompleted ? "line-through" : "none",
					}}
					style={{ backgroundColor: isActive ? colors.accent : colors.primary }}
				>
					<View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
						<Text style={{ color: 'white' }}>{item.track.song_title}</Text>
					</View>
				</ListItem>
			</ScaleDecorator>
		)

	}




	return !edit ? (
		<TrackListView />
	) : (
		// <ScrollView style={{ flex: 1 }}>
		<GestureHandlerRootView>
			<ThemeProvider theme={theme}>
				<DraggableFlatList
					// scrollEnabled={scrollEnabled}
					// onDragBegin={() => setScrollEnabled(false)}
					data={tracksList}
					onDragEnd={({ data, from, to }) => {
						return setTrackList(data)
					}}
					keyExtractor={item => item.id}
					renderItem={renderItem}
					ListHeaderComponent={Header}
				// containerStyle={{ height: Platform.OS === 'web' ? '100%' : '95%' }}
				// outerScrollOffset={scrollOffset}
				/>
			</ThemeProvider>
		</GestureHandlerRootView>
		// </ScrollView>
	)
}
