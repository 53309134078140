import { Ionicons } from '@expo/vector-icons'
import React, { useState } from 'react'
import { Pressable, View } from 'react-native'
import { colors } from '../../../../../Helpers/variables'
import useSound from '../../../../../Hooks/useSound'
import QueueModal from '../../../../Modals/QueueModal'

const QueueButton = () => {
	const {
		currentPlayingTrack,
		trackList,
		currentPlayingTrackIndex,
		setTrackList,
		setCurrentPlayingTrackIndex
	} = useSound()
	const [queueModalOpen, setQueueModalOpen] = useState(false)

	const handleClickQueue = () => {
		setQueueModalOpen(true)
	}

	return (
		<View style={{ marginLeft: 10, marginTop: -3 }}>
			<Pressable disabled={currentPlayingTrack === null}>
				<Ionicons
					name="list-outline"
					size={30}
					color={currentPlayingTrack === null ? colors.secondary : 'white'}
					onPress={() => handleClickQueue()}
				/>
			</Pressable>
			{queueModalOpen && (
				<QueueModal
					setModalOpen={setQueueModalOpen}
					isModalOpen={queueModalOpen}
					trackList={trackList}
					currentPlayingTrackIndex={currentPlayingTrackIndex}
					setTrackList={setTrackList}
					setCurrentPlayingTrackIndex={setCurrentPlayingTrackIndex}
				/>
			)}
		</View>
	)
}

export default React.memo(QueueButton)
