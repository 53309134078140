import Checkbox from 'expo-checkbox'
import React from 'react'
import { FlatList, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import { colors } from '../../Helpers/variables'
import { CheckboxTicker } from './CheckboxTicker'

const styles = StyleSheet.create({
	main: {
		width: 195, 
		height: 320
	},
	container: {
		flex: 1,
		marginHorizontal: 16,
		color: 'white',
		backgroundColor: colors.primary,
		marginTop: 15,
		height: 280,
	},
	title: {
		color: 'white',
		marginLeft: 20,
		fontSize: 16
	},
	section: {
		marginTop: 10,
		marginLeft: 10,
		flexDirection: 'row',
		alignItems: 'center'
	},
	paragraph: {
		fontSize: 15,
		color: 'white'
	},
	checkbox: {
		margin: 8
	}
})

export const CheckListbox = ({
	listboxItems,
	setListboxItems,
	hasSelectAll = false,
	hasListboxSelectedItems = false,
	refreshListboxSelectedItems,
	title,
	handleRemoveAll,
	isSelectAllChecked,
	setSelectAllChecked,
	emptyLabel = 'Ingen spillelister'
}) => {
	const handleCheckboxClick = (item, isChecked) => {
		let selectedItem = listboxItems.filter(x => x.id === item.id)[0]
		selectedItem.isChecked = !isChecked
		let listboxItemsTemp = listboxItems.filter(x => x.id !== item.id)
		listboxItemsTemp.push(selectedItem)
		listboxItemsTemp = listboxItemsTemp.sort(function (a, b) {
			return a.id - b.id
		})
		setListboxItems(listboxItemsTemp)
		if (hasListboxSelectedItems) refreshListboxSelectedItems(item, !isChecked)
	}

	const handleSelectAll = () => {
		let listboxItemsTemp = []
		let isCheckedTemp = !isSelectAllChecked
		if (isCheckedTemp === true) {
			listboxItemsTemp = listboxItems.filter(x => (x.isChecked = isCheckedTemp))
			setListboxItems(listboxItemsTemp)
			listboxItemsTemp.map(item => {
				refreshListboxSelectedItems(item, isCheckedTemp)
			})
			setSelectAllChecked(isCheckedTemp)
		} else {
			handleRemoveAll()
		}
	}

	return (
		<View style={styles.main}>
			<View>
				<Text style={styles.title}>{title}</Text>
			</View>
			{hasSelectAll && (
				<Pressable
					onPress={() => {
						handleSelectAll()
					}}
				>
					<View style={styles.section}>
						<Checkbox
							style={styles.checkbox}
							value={isSelectAllChecked}
							color={isSelectAllChecked ? colors.accent : undefined}
						/>
						<Text style={styles.paragraph}>{isSelectAllChecked ? 'Fravælg alle' : 'Vælg alle'}</Text>
					</View>
				</Pressable>
			)}
			<ScrollView style={styles.container}>
				<FlatList
					data={listboxItems}
					ListEmptyComponent={
						<View>
							<Text style={{ color: colors.accent, margin: 10, fontSize: 20 }}>{emptyLabel}...</Text>
						</View>
					}
					renderItem={({ item }) => (
						<CheckboxTicker
							isChecked={item.isChecked}
							label={item.username ? item.username : item.name}
							handleCheckboxClick={handleCheckboxClick}
							item={item}
							style={{ fontSize: 15 }}
						/>
					)}
					showsVerticalScrollIndicator={false}
					showsHorizontalScrollIndicator={false}
					keyExtractor={item => item.id}
				/>
			</ScrollView>
		</View>
	)
}
