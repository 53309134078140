import { Modal, Pressable, Text, View } from 'react-native'

import { StyleSheet } from 'react-native'
import { colors } from '../../Helpers/variables'

export const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		minWidth: 200,
		alignItems: 'center',
		justifyContent: 'center'
	},
	button: {
		marginTop: 30,
		borderRadius: 20,
		padding: 10,
		elevation: 2,
		width: 120
	},
	buttonClose: {
		backgroundColor: colors.primary
	},
	textStyle: {
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center',
		color: 'white',
		fontSize: 15
	},
	buttonView: {
		alignItems: 'flex-end',
		justifyContent: 'flex-end'
	}
})

export const Popup = ({ modalVisible, setModalVisible, description }) => {
	return (
		<View style={styles.centeredView}>
			<Modal
				animationType="slide"
				transparent={true}
				visible={modalVisible}
				onRequestClose={() => {
					setModalVisible(prev => !prev)
				}}
			>
				<View style={styles.centeredView}>
					<View style={styles.modalView}>
						<Text style={styles.modalText}>{description}</Text>
						<View style={styles.buttonView}>
							<Pressable style={[styles.button, styles.buttonClose]} onPress={() => setModalVisible(!modalVisible)}>
								<Text style={styles.textStyle}>Luk</Text>
							</Pressable>
						</View>
					</View>
				</View>
			</Modal>
		</View>
	)
}
