import { Ionicons, MaterialIcons } from '@expo/vector-icons'
import Constants from 'expo-constants'
import { Image, Pressable, Text, useWindowDimensions, View } from 'react-native'

import { useEffect, useState } from 'react'
import useLayout from '../../../Hooks/useLayout'

// For Step by Step Walkthrough
import { CopilotStep, useCopilot, walkthroughable } from 'react-native-copilot'
import { TourModal } from '../../Modals/TourModal'

import LinkButton from './components/LinkButton'
import PlayButton from './components/TrackControls/PlayButton'
import TrackChangeButton from './components/TrackControls/TrackChangeButton'

import TermsConditionsModal from '../../Modals/TermsConditionsModal'
import TrackControls from './components/TrackControls'
import useSound from '../../../Hooks/useSound'
import { colors } from '../../../Helpers/variables'
import AnnouncementsModal from '../../Modals/AnnouncementsModal'

const WalkthroughableView = walkthroughable(View)

const Sidebar = props => {
	const {
		handleTrackPlayer,
		handleClickShuffle,
		isShuffleActive,
		handleClickRepeat,
		repeatType,
		mediaPlayerAcquisition,
		currentPlayingTrack,
		setIsShuffleActive
	} = useSound()

	const windowHeight = useWindowDimensions().height
	const { isSidebarOpen, toggleSidebar, deviceType, setIsShowHeaderTour } = useLayout()
	const [tourModal, setTourModal] = useState({
		title: undefined,
		description: undefined
	})
	const { copilotEvents } = useCopilot()

	useEffect(() => {
		//setting a function to handle the step change event
		copilotEvents.on('stepChange', handleStepChange)
		copilotEvents.on('stop', handleStop)

		return () => {
			copilotEvents.off('stop')
			copilotEvents.off('stepChange')
			copilotEvents.off('start')
		}
	}, [])

	const handleStepChange = step => {
		//Handler, in case we want to handle the step change
		console.log(`Current step is: ${step.order}`)
	}

	const handleStop = (isSkipTour = false) => {
		if (!isSkipTour) setIsShowHeaderTour(true)
		props.stop
	}

	return (
		<View
			style={{
				flex: 1,
				justifyContent: 'space-between',
				alignItems: 'flex-start'
			}}
		>
			<View style={{ paddingHorizontal: 25 }}>
				<View style={{ paddingHorizontal: 0, marginBottom: 20, marginTop: 10 }}>
					{deviceType !== 1 ? (
						<Image
							source={require('../../../assets/music_mind_logo.png')}
							style={{
								width: 160,
								height: 60
							}}
							resizeMode={'contain'}
						/>
					) : (
						<Pressable onPress={toggleSidebar}>
							<Ionicons name={'ios-menu'} size={30} color={'#ffffff'} />
						</Pressable>
					)}
				</View>

				<LinkButton open={isSidebarOpen} route="HomeScreen" title={'Hjem'} icon={'ios-home'} />
				<LinkButton
					open={isSidebarOpen}
					route="InternalContainerScreen"
					title={'Internt Miljø'}
					icon={'musical-notes-sharp'}
				/>
				<LinkButton open={isSidebarOpen} route="MyPlaylistsScreen" title={'Mine spillelister'} icon={'ios-albums'} />
				<LinkButton
					open={isSidebarOpen}
					route="CreatePlaylistScreen"
					title={'Opret Spilleliste'}
					icon={'ios-library-sharp'}
				/>
				<Text style={{ fontSize: 10, color: 'white' }}>V {Constants.expoConfig.version}</Text>
			</View>
			{deviceType !== 1 ? (
				<TrackControls CopilotStep={CopilotStep} WalkthroughableView={WalkthroughableView} />
			) : (
				<View
					style={{
						alignItems: 'center',
						justifyContent: 'space-between',
						flexDirection: isSidebarOpen ? 'row' : 'column',
						paddingHorizontal: 10,
						paddingVertical: 10
					}}
				>
					<Pressable disabled={currentPlayingTrack === null}>
						<Ionicons
							name="shuffle"
							size={26}
							color={currentPlayingTrack === null ? colors.secondary : isShuffleActive ? colors.accent : 'white'}
							style={{ marginHorizontal: 10 }}
							onPress={() => handleClickShuffle()}
						/>
					</Pressable>
					<TrackChangeButton type={'prev'} />
					<PlayButton />
					<TrackChangeButton type={'next'} />
					<Pressable disabled={currentPlayingTrack === null}>
						{repeatType === 'one' ? (
							<MaterialIcons
								name="repeat-one"
								size={26}
								color={currentPlayingTrack === null ? colors.secondary : colors.accent}
								style={{ marginHorizontal: 10 }}
								onPress={() => handleClickRepeat(isRepeatAllTracks ? 'repeatAllTracks' : 'none')}
							/>
						) : (
							<Ionicons
								name="ios-repeat-sharp"
								size={26}
								color={currentPlayingTrack === null ? colors.secondary : repeatType === 'all' ? colors.accent : 'white'}
								style={{ marginHorizontal: 10 }}
								onPress={() => handleClickRepeat(repeatType === 'none' ? 'all' : 'one')}
							/>
						)}
					</Pressable>
				</View>
			)}
			{props.isTourModalVisible && (
				<TourModal
					title={tourModal.title}
					description={tourModal.description}
					visible={props.isTourModalVisible}
					skipTour={handleStop}
					startTour={props.start}
					setTourModal={props.handleCancelGuidedTour}
				/>
			)}

			{props.isTermsConditionsModalVisible && (
				<TermsConditionsModal
					visible={props.isTermsConditionsModalVisible}
					handleAgreeTermsConditions={props.handleAgreeTermsConditions}
					handleCancelTermsConditions={props.handleCancelTermsConditions}
				/>
			)}
			{props.isAnnouncementsModalVisible && (
				<AnnouncementsModal
					visible={props.isAnnouncementsModalVisible}
					handleAgreeAnnouncements={props.handleAgreeAnnouncements}
				/>
			)}
		</View>
	)
}

export default Sidebar
