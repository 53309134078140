import React, { useEffect, useState } from 'react'
import { Alert, FlatList, Platform, Text, View } from 'react-native'
import * as Sentry from 'sentry-expo'
import { NotificationModal } from '../../Components/Modals/NotificationModal'
import { playlistSortByName } from '../../Helpers/sortArray'
import { USER_PLAYLIST_URL, colors } from '../../Helpers/variables'
import useAxios from '../../Hooks/useAxios'
import SearchResultTrackCard from './components/SearchResultTrackCard'

export default function SearchResultTrack({ tracks, mostRelated }) {
	const [userPlaylists, setUserPlaylists] = useState([])
	const [notifModal, setNotifModal] = useState({
		visible: false,
		title: '',
		description: '',
		isDisappearing: false
	})
	const api = useAxios()

	const closeNotifModal = () => {
		setNotifModal({
			visible: false,
			title: '',
			description: '',
			isDisappearing: false
		})
	}

	const openNotifModal = (title, description, isDisappearing = false) => {
		setNotifModal({
			visible: true,
			title: title,
			description: description,
			isDisappearing: isDisappearing
		})
	}

	const updateUserPlaylists = (id, name, description, visibility) => {
		let userPlaylistsTemp = userPlaylists
		userPlaylistsTemp.push({ id: id, name: name, description: description, visibility: visibility })
		userPlaylistsTemp = playlistSortByName(userPlaylistsTemp)
		setUserPlaylists(userPlaylistsTemp)
	}

	useEffect(() => {
		async function boot() {
			await api
				.get(USER_PLAYLIST_URL)
				.then(({ data }) => {
					data = playlistSortByName(data)
					setUserPlaylists(data)
				})
				.catch(error => {
					console.log(error)
					if (Platform.OS === 'web') Sentry.Browser.captureException(error)
					else Sentry.Native.captureException(error)
				})
		}
		boot()
	}, [])

	return (
		<View>
			{mostRelated && tracks && (
				<View>
					{mostRelated.length === 0 && tracks.length === 0 && (
						<Text style={{ color: 'white', marginLeft: 20, marginTop: 10 }}>Ingen data...</Text>
					)}
				</View>
			)}

			{mostRelated && (
				<View>
					{mostRelated.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 20, color: colors.accent, fontWeight: 'bold', marginTop: 10 }}>
								Mest beslægtet
							</Text>
							<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
								<FlatList
									data={mostRelated}
									renderItem={({ item, index }) => (
										<SearchResultTrackCard
											track={item}
											userPlaylists={userPlaylists}
											updateUserPlaylists={updateUserPlaylists}
											openNotifModal={openNotifModal}
											closeNotifModal={closeNotifModal}
											item={item}
											key={item.id}
											tracks={tracks}
											index={index}
										/>
									)}
									keyExtractor={item => item.id}
									//if the user pulls from the top of the screen, log out to the console:
									onRefresh={() => console.log('refreshing')}
									//if set to true, the UI will show a loading indicator and trigger data.
									refreshing={false}
									horizontal={true}
								/>
							</View>
						</View>
					)}
				</View>
			)}

			{tracks && (
				<View>
					{tracks.length > 0 && (
						<View>
							<Text style={{ marginLeft: 20, fontSize: 20, color: colors.accent, fontWeight: 'bold', marginTop: 10 }}>
								Mindre beslægtet
							</Text>
							{/* <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', width: '90%' }}> */}
							<FlatList
								data={tracks}
								renderItem={({ item }) => (
									<SearchResultTrackCard
										track={item}
										userPlaylists={userPlaylists}
										updateUserPlaylists={updateUserPlaylists}
										openNotifModal={openNotifModal}
										closeNotifModal={closeNotifModal}
										item={item}
										key={item.id}
									/>
								)}
								keyExtractor={item => item.id}
								//if the user pulls from the top of the screen, log out to the console:
								onRefresh={() => console.log('refreshing')}
								//if set to true, the UI will show a loading indicator and trigger data.
								refreshing={false}
								horizontal={true}
							// contentContainerStyle={{ width: '90%' }}
							// bounces={false}
							/>
							{/* </View> */}
						</View>
					)}
				</View>
			)}
			{notifModal.visible && (
				<NotificationModal
					title={notifModal.title}
					description={notifModal.description}
					visible={notifModal.visible}
					closeNotifModal={closeNotifModal}
					isDisappearing={notifModal.isDisappearing}
				/>
			)}
		</View>
	)
}
