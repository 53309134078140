import * as FileSystem from 'expo-file-system'
import { Platform } from 'react-native'
import * as Sentry from 'sentry-expo'

/* -------------------------------------------------------------------------- */
/*                 TODO: Fix the filesystem to be more robust                 */
/* -------------------------------------------------------------------------- */

const useFiles = () => {
	const tracksDir = FileSystem.documentDirectory + 'tracks/'
	const photoDir = FileSystem.documentDirectory + 'photos/'

	const trackFileUri = (track_id, extention) => tracksDir + `track_${track_id}.${extention}`
	const photoUri = (type, id, extention) => photoDir + `${type}_${id}.${extention}`

	const getPhoto = photo => photoDir + photo

	const callback = downloadProgress =>
		console.log(downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite)

	/* -------------------------------------------------------------------------- */
	/*          Checks if track directory exists. If not, then create it          */
	/* -------------------------------------------------------------------------- */
	async function ensureTrackDirExists() {
		const dirInfo = await FileSystem.getInfoAsync(tracksDir)
		if (!dirInfo.exists) {
			console.log("Tracks directory doesn't exist, creating...")
			await FileSystem.makeDirectoryAsync(tracksDir, { intermediates: true })
		}
	}

	async function ensurePhotoDirExists() {
		const dirInfo = await FileSystem.getInfoAsync(photoDir)
		if (!dirInfo.exists) {
			console.log("Photos directory doesn't exist, creating...")
			await FileSystem.makeDirectoryAsync(photoDir, { intermediates: true })
		}
	}

	/* -------------------------------------------------------------------------- */
	/*          Download Photo and return photo path to store in database         */
	/* -------------------------------------------------------------------------- */
	async function downloadPhoto(type, id, fileUri) {
		await ensurePhotoDirExists() // make sure that directory exists for file downloading

		let extention = fileUri.split('.')[3].split('?')[0] // get file extention from the uri

		console.log(fileUri.split('.')[2], fileUri.split('.')[3].split('?')[0])

		const fileInfo = await FileSystem.getInfoAsync(photoUri(type, id, extention)) // check if file already exists
		if (fileInfo.exists) return `${type}_${id}.${extention}`

		/* ----------------- create a resumable file download object ---------------- */
		const downloadResumable = FileSystem.createDownloadResumable(fileUri, photoUri(type, id, extention), {}, callback)

		try {
			const { uri } = await downloadResumable.downloadAsync()
			console.log('Finished downloading ', `${type}_${id}.${extention}`)
		} catch (e) {
			console.error(e)
			Sentry.Native.captureException(e)
		}

		return `${type}_${id}.${extention}`
	}

	/* -------------------------------------------------------------------------- */
	/*            Download Tracks and return path to store in database            */
	/* -------------------------------------------------------------------------- */
	async function downloadTrack(id, fileUri) {
		await ensureTrackDirExists() // make sure that directory exists for file downloading

		let extention = fileUri.split('.')[3].split('?')[0] // get file extention from the uri

		const fileInfo = await FileSystem.getInfoAsync(trackFileUri(id, extention)) // check if file already exists
		if (fileInfo.exists) return `track_${id}.${extention}`

		/* ----------------- create a resumable file download object ---------------- */
		const downloadResumable = FileSystem.createDownloadResumable(fileUri, trackFileUri(id, extention), {}, callback)

		try {
			const { uri } = await downloadResumable.downloadAsync()
			console.log('Finished downloading ', `track_${id}.${extention}`)
		} catch (e) {
			console.error(e)
			Sentry.Native.captureException(e)
		}

		return `track_${id}.${extention}`
	}

	/* -------------------------------------------------------------------------- */
	/*               Downloads all tracks specified as array of IDs               */
	/* -------------------------------------------------------------------------- */
	async function addMultipleTracks(trackList) {
		try {
			console.log('Downloading [', trackList.length, '] track files...')
			await Promise.all(trackList.map(track => downloadTrack(track.track.id, track.track.track_file)))
		} catch (e) {
			console.error("Couldn't download track files:", e)
			Sentry.Native.captureException(e)
		}
	}

	/* -------------------------------------------------------------------------- */
	/*           Get a single tracks info, if it exists in phone storage          */
	/* -------------------------------------------------------------------------- */
	async function getSingleTrack({ id, track_file }) {
		await ensureTrackDirExists()

		const fileUri = trackFileUri(id, track_file)
		const fileInfo = await FileSystem.getInfoAsync(fileUri)

		/* -------------------------------------------------------------------------- */
		/*    If tracks dosn't exists return false to handle the error in component   */
		/* -------------------------------------------------------------------------- */
		if (!fileInfo.exists) {
			console.log("Track isn't cached locally.")
			return false
		}

		return fileUri
	}

	/* -------------------------------------------------------------------------- */
	/*             Deletes whole tracks directory with all its content            */
	/* -------------------------------------------------------------------------- */
	async function deleteAllTracks() {
		try {
			console.log('Deleting all track files...')
			await FileSystem.deleteAsync(tracksDir)
		} catch (e) {
			console.error("Couldn't delete track files:", e)
			if (Platform.OS === 'web') Sentry.Browser.captureException(e)
			else Sentry.Native.captureException(e)
		}
	}

	async function getAllTracks() {
		try {
			console.log('Getting all tracks')
		} catch (e) {
			console.error("Couldn't locate track files:", e)
			if (Platform.OS === 'web') Sentry.Browser.captureException(e)
			else Sentry.Native.captureException(e)
		}
	}

	function isImage(url) {
		return /\.(jpg|jpeg|png)$/.test(url)
	}

	return {
		addMultipleTracks,
		getSingleTrack,
		deleteAllTracks,
		downloadPhoto,
		getPhoto,
		isImage
	}
}

export default useFiles
