import { Platform } from 'react-native'
import * as Sentry from 'sentry-expo'

export function captureException(err, data) {
	if (Platform.OS === 'web') Sentry.Browser.captureException(err)
	else Sentry.Native.captureException(err)
}

export function logException(err) {
	captureException(new Error(JSON.stringify(err)))
}
