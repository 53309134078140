import { Modal, StyleSheet, View } from 'react-native'
import { TERMS_CONDITIONS_URL, colors } from '../../Helpers/variables'
import NewFeedScreen from '../../Screens/NewsFeedScreen/NewsFeedScreen'
import { SecondaryButton } from '../Buttons/Button'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { ScrollView } from 'react-native'
import { Text } from 'react-native'
import useTokens from '../../Hooks/useTokens'

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: 'gray',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		minWidth: 350,
		maxWidth: 700
	},
	label: {
		marginBottom: 20,
		color: 'white',
		fontSize: 20,
		marginTop: 10
	},
	errorMessage: {
		color: 'red',
		marginBottom: 10
	},
	header: {
		color: 'white',
		fontSize: 28,
		fontWeight: 'bold',
		paddingLeft: 20,
		paddingTop: 10
	},
	body: {
		color: 'white',
		fontSize: 18,
		paddingLeft: 20,
		paddingLeft: 20,
		paddingRight: 20
	}
})

const TermsConditionsModal = ({
	modalVisible,
	// setModalVisible,
	handleAgreeTermsConditions,
	handleCancelTermsConditions
}) => {
	const [terms, setTerms] = useState({ title: '', content: '' })
	const { getTokens } = useTokens()

	const getTermsAndConditions = async () => {
		console.log('getTermsAndConditions')
		const { access } = await getTokens()
		try {
			const { data, status } = await axios.get(TERMS_CONDITIONS_URL, {
				headers: { Authorization: `JWT ${access}` }
			})
			if (data.length > 0) {
				setTerms(data[0])
			}
		} catch (ex) {
			console.log(ex)
		}
	}

	useEffect(() => {
		getTermsAndConditions()
	}, [])

	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible()
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<ScrollView style={{ maxWidth: 580, minWidth: 300 }}>
						<Text style={styles.header}>{terms.title}</Text>
						<Text style={styles.body}>{terms.content}</Text>
					</ScrollView>
					<View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', marginTop: 10 }}>
						<SecondaryButton title={'Anuller'} onPress={() => handleCancelTermsConditions()} style={{ width: 180 }} />
						<SecondaryButton
							title={'Enig'}
							onPress={() => handleAgreeTermsConditions()}
							style={{ width: 180, margin: 10 }}
						/>
					</View>
				</View>
			</View>
		</Modal>
	)
}

export default TermsConditionsModal
