/* ----------------------------- Library imports ---------------------------- */
import { useEffect, useState } from 'react'
import { Platform, ScrollView, Text, useWindowDimensions, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'

/* --------------------------- Component Imports ---------------------------- */
import Loader from '../../Components/Loader/Loader'
import { Heading } from '../../Components/Typography/Typography'
import CategoryCard from './components/CategoryCard'

/* ------------------------------ Hooks imports ----------------------------- */
import useAxios from '../../Hooks/useAxios'
import useLayout from '../../Hooks/useLayout'

/* ------------------------------ Data helpers ------------------------------ */
import { addDataToContainersTable, retrieveDataFromContainersTable } from '../../Helpers/database/containersTable'
import { colors, MAIN_CONTAINER_URL } from '../../Helpers/variables'

/* ---------------------------- Exception handler --------------------------- */
import { ResetButton } from '../../Components/Buttons/Button'
import { Input } from '../../Components/Forms/Input'
import RefreshButton from '../../Components/RefreshButton'
import { captureException } from '../../Helpers/sentryLog'
import { isCachedDataValid } from '../../Helpers/timeConverter'

export default function HomeScreen({ navigation }) {
	const api = useAxios()

	const { deviceType, isOffline } = useLayout()

	const [containers, setContainer] = useState([])
	const [originalContainers, setOriginalContainers] = useState([])
	const [loading, setLoading] = useState(true)
	const [wasDataCached, setWasDataCached] = useState(false)

	const [shouldRefresh, setShouldRefresh] = useState(false)
	const [error, setError] = useState(false)
	const [shouldReset, setShouldReset] = useState(false)

	const getDataFromApi = async () => {
		// await new Promise(r => setTimeout(r, 1000))
		const { data } = await api.get(MAIN_CONTAINER_URL)
		setContainer(data)
		setOriginalContainers(data)
		addDataToContainersTable(data)
	}

	const handleSearch = target => {
		let newContainers = containers.filter(x => x.name.toLowerCase().includes(target.nativeEvent.text.toLowerCase()))
		setContainer(newContainers)
	}

	const onChangeText = text => {
		if (text === '') {
			setContainer(originalContainers)
		}
	}

	useEffect(() => {
		if (shouldReset) {
			setError(false)
			setLoading(true)
			async function boot() {
				try {
					if (Platform.OS === 'web') {
						await getDataFromApi()
						return
					}

					await getDataFromApi()
				} catch (error) {
					console.error(error)
					captureException(error)
					setError(true)
				} finally {
					setLoading(false)
					setShouldReset(false)
				}
			}

			boot()
		}
	}, [shouldReset])

	useEffect(() => {
		setError(false)
		async function boot() {
			try {
				if (Platform.OS === 'web' || !isOffline) {
					await getDataFromApi()
					return
				}
				const cachedData = await retrieveDataFromContainersTable()
				if (!isCachedDataValid(cachedData)) {
					await getDataFromApi()
				} else {
					setWasDataCached(true)
					setContainer(cachedData)
					setOriginalContainers(cachedData)
				}
			} catch (error) {
				console.error(error)
				captureException(error)
				setError(true)
			} finally {
				setLoading(false)
			}
		}

		boot()
	}, [shouldRefresh])

	if (error) return <RefreshButton setShouldRefresh={setShouldRefresh} />
	return loading ? (
		<Loader />
	) : (
		<View style={{ backgroundColor: colors.primary, flex: 1 }}>
			<ScrollView>
				<View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
					<ResetButton style={{ width: 100 }} onPress={() => setShouldReset(true)} />
					<Input
						style={{ marginTop: 10, marginLeft: 10, flexGrow: 1 }}
						onSubmitEditing={handleSearch}
						placeholder={'Søg præoprettet spillelister'}
						onChangeText={onChangeText}
					/>
				</View>
				<Heading style={{ color: colors.accent }}>Kategorier</Heading>
				<FlatGrid
					data={containers}
					renderItem={({ item }) => (
						<CategoryCard
							wasDataCached={wasDataCached}
							item={item}
							navigate={navigation.navigate}
							deviceType={deviceType}
						/>
					)}
					ListEmptyComponent={<Text style={{ color: 'white' }}>Ingen data...</Text>}
					keyExtractor={item => item.id}
					itemDimension={200}
					// additionalRowStyle={{ padding: 0 }}
					contentContainerStyle={{ paddingBottom: 90 }}
					style={{ flex: 1 }}
				/>
			</ScrollView>
		</View>
	)
}
