import { Modal, StyleSheet, View } from 'react-native'
import { colors } from '../../Helpers/variables'
import UserManualScreen from '../../Screens/UserManualScreen/UserManualScreen'
import { SecondaryButton } from '../Buttons/Button'

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		backgroundColor: colors.secondary,
		borderRadius: 20,
		padding: 35,
		shadowColor: 'gray',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
		width: '70%',
		height: '90%'
		// minWidth: 200
		// alignItems: 'center',
		// justifyContent: 'center',
	},
	label: {
		marginBottom: 20,
		color: 'white',
		fontSize: 20,
		marginTop: 10
	},
	errorMessage: {
		color: 'red',
		marginBottom: 10
	}
})

const UserManualModal = ({ modalVisible, setModalVisible }) => {
	return (
		<Modal
			animationType="slide"
			transparent={true}
			visible={modalVisible}
			onRequestClose={() => {
				setModalVisible(false)
			}}
		>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					<UserManualScreen />
					<View style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
						<SecondaryButton title={'Luk'} onPress={() => setModalVisible(false)} style={{ width: 180, margin: 10 }} />
					</View>
				</View>
			</View>
		</Modal>
	)
}

export default UserManualModal
