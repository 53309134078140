import { Modal, View } from 'react-native'
import CreatePlaylistScreen from '../../../Screens/CreatePlaylistScreen/CreatePlaylistScreen'
import { trackStyles } from './TrackStyles'

export const CreatePlaylistModal = ({
	modalVisible,
	setModalVisible,
	playlistInfo,
	processType = 'add',
	updateUserPlaylists = undefined
}) => {
	return (
		// <View style={trackStyles.centeredView}>
		<Modal animationType="slide" transparent={true} visible={modalVisible}>
			<View style={trackStyles.centeredView}>
				<View style={trackStyles.modalView}>
					<CreatePlaylistScreen
						setModalVisible={setModalVisible}
						playlistInfo={playlistInfo}
						processType={processType}
						updateUserPlaylists={updateUserPlaylists}
					/>
				</View>
			</View>
		</Modal>
		// </View>
	)
}
