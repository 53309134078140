import React from 'react'
import { Text, View } from 'react-native'
import { FlatGrid } from 'react-native-super-grid'
import { colors } from '../../Helpers/variables'
import CarouselCardItem from './CarouselCardItem'

const CarouselCards = ({ data }) => {
	return (
		<View style={{ marginVertical: 10, height: '85%' }}>
			<FlatGrid
				itemDimension={400}
				data={data}
				renderItem={({ item }) => <CarouselCardItem item={item} />}
				ListEmptyComponent={
					<View>
						<Text style={{ color: colors.accent, marginTop: 30, fontSize: 20 }}>Ingen data</Text>
					</View>
				}
				keyExtractor={item => item.id}
				style={{ flex: 1 }}
				contentContainerStyle={{ paddingBottom: 20 }}
				showsVerticalScrollIndicator={true}
				showsHorizontalScrollIndicator={true}
				horizontal={true}
			/>
		</View>
	)
}

export default CarouselCards
