import React, { useEffect, useState } from 'react'
import { Image, ImageBackground, Text, View } from 'react-native'
import { MEDIAPLAYER_ACQUISITION_URL, colors } from '../../../../Helpers/variables'
import useAxios from '../../../../Hooks/useAxios'

import truncateStr from '../../../../Helpers/truncateStr'
import useAuth from '../../../../Hooks/useAuth'
import useSound from '../../../../Hooks/useSound'
import PlayButton from './TrackControls/PlayButton'
import QueueButton from './TrackControls/QueueButton'
import RepeatButton from './TrackControls/RepeatButton'
import SeekControls from './TrackControls/SeekControls'
import ShuffleButton from './TrackControls/ShuffleButton'
import TimerControls from './TrackControls/TimerControls'
import TrackChangeButton from './TrackControls/TrackChangeButton'
import VolumeControls from './TrackControls/VolumeControls'

const fallback = require('../../../../assets/fallback_cat.png')
const logo = require('../../../../assets/icon.png')
export default function TrackControls({ CopilotStep, WalkthroughableView }) {
	const { currentPlayingTrack, mediaPlayerAcquisition, setMediaPlayerAcquisition } = useSound()
	const [loading, setLoading] = useState(true)
	const api = useAxios()
	const { isAdmin } = useAuth()

	const getMediaPlayerAcquisition = async () => {
		const { data } = await api.get(MEDIAPLAYER_ACQUISITION_URL)
		if (data.length > 0) {
			setMediaPlayerAcquisition({
				isShuffleBtnVisible: isAdmin ? true : data[0].shuffle_enabled_bool,
				isRepeatBtnVisible: isAdmin ? true : data[0].repeat_enabled_bool
			})
		} else
			setMediaPlayerAcquisition({
				isShuffleBtnVisible: true,
				isRepeatBtnVisible: true
			})
	}

	useEffect(() => {
		getMediaPlayerAcquisition()
		setLoading(false)
	}, [])

	const [image, setImage] = useState(undefined)

	useEffect(() => {
		if (currentPlayingTrack) {
			setImage(currentPlayingTrack.album_photo)
		}
	}, [currentPlayingTrack])

	return (
		<View style={{ paddingHorizontal: 15, marginBottom: 20, marginTop: currentPlayingTrack ? '12%' : '90%' }}>
			{currentPlayingTrack && (
				<View style={{ alignItems: 'center' }}>
					<ImageBackground
						blurRadius={20}
						imageStyle={{ borderRadius: 15 }}
						source={image ? { uri: image } : fallback}
						style={{ width: 200 }}
						resizeMode={'cover'}
					>
						<Image
							source={image ? { uri: image } : logo}
							style={{
								height: 125,
								width: 125,
								borderRadius: 100,
								marginLeft: 40,
								marginTop: 25,
								borderColor: colors.accent,
								borderWidth: 2
							}}
							resizeMode={'cover'}
						/>
						<View style={{ paddingVertical: 20 }}>
							<Text style={{ color: 'white', textAlign: 'center', fontSize: 14 }}>
								{truncateStr(currentPlayingTrack.song_title, 20)}
							</Text>
							<Text style={{ color: 'white', textAlign: 'center', fontSize: 10 }}>
								{truncateStr(currentPlayingTrack.artist_name, 20)}
							</Text>
						</View>
					</ImageBackground>
				</View>
			)}
			<SeekControls />
			<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
				{mediaPlayerAcquisition.isShuffleBtnVisible && (
					<CopilotStep order={5} name={'asjdfhnvuwfive'} text={'Blander numrene tilfældigt'}>
						<WalkthroughableView>
							<ShuffleButton />
						</WalkthroughableView>
					</CopilotStep>
				)}
				<CopilotStep
					order={mediaPlayerAcquisition.isShuffleBtnVisible ? 6 : 5}
					name={'asjdfhnvuwsix'}
					text={'Afspil forrige sang'}
				>
					<WalkthroughableView>
						<TrackChangeButton type="prev" />
					</WalkthroughableView>
				</CopilotStep>
				<CopilotStep
					order={mediaPlayerAcquisition.isShuffleBtnVisible ? 7 : 6}
					name={'asjdfhnvuwseven'}
					text={'Afspil sang'}
				>
					<WalkthroughableView>
						<PlayButton />
					</WalkthroughableView>
				</CopilotStep>
				<CopilotStep
					order={mediaPlayerAcquisition.isShuffleBtnVisible ? 8 : 7}
					name={'asjdfhnvuweight'}
					text={'Afspil næste sang'}
				>
					<WalkthroughableView>
						<TrackChangeButton type="next" />
					</WalkthroughableView>
				</CopilotStep>
				{mediaPlayerAcquisition.isRepeatBtnVisible && (
					<CopilotStep
						order={mediaPlayerAcquisition.isShuffleBtnVisible ? 9 : 8}
						name={'asjdfhnvuwnine'}
						text={'Gentag alle sange og gentag én sang'}
					>
						<WalkthroughableView>
							<RepeatButton />
						</WalkthroughableView>
					</CopilotStep>
				)}
			</View>
			<CopilotStep
				order={mediaPlayerAcquisition.isShuffleBtnVisible ? 10 : 9}
				name={'asjdfhnvuwten'}
				text={'Skift sangens lydstyrke'}
			>
				<WalkthroughableView>
					<VolumeControls />
				</WalkthroughableView>
			</CopilotStep>
			<View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, width: 250 }}>
				<CopilotStep
					order={mediaPlayerAcquisition.isShuffleBtnVisible ? 11 : 10}
					name={'asjdfhnvuw11'}
					text={'Tænd/sluk timeren'}
				>
					<WalkthroughableView>
						<TimerControls />
					</WalkthroughableView>
				</CopilotStep>

				<CopilotStep order={mediaPlayerAcquisition.isShuffleBtnVisible ? 12 : 11} name={'asjdfhnvuw12d'} text={'Kø'}>
					<WalkthroughableView>
						<QueueButton />
					</WalkthroughableView>
				</CopilotStep>
			</View>
		</View>
	)
}
